<div class="section">
    <div class="l-score">
        <div class="score">
            <div class="score_team clickable" (click)="switchPlayers('A')">
                <span class="team_name"> <i class="float-right fa-sm fas fa-shield-alt"></i>{{ match.teamA[0].displayName }}</span>
                <span class="team_name"> <i class="float-right fa-sm fas fa-futbol"></i>{{ match.teamA[1].displayName }}</span>
            </div>
            <input
                #txtScoreTeamA
                class="score_input"
                type="number"
                max="2"
                [ngModel]="scoreTeamA"
                (ngModelChange)="scoreTeamAChange($event)"
                (keyup)="scoreKeyUp($event)"
            />
        </div>
        <div class="score">
            <input #txtScoreTeamB class="score_input" type="number" max="2" [(ngModel)]="scoreTeamB" (keyup)="scoreKeyUp($event)" />
            <div class="score_team clickable" (click)="switchPlayers('B')">
                <span class="team_name"> <i class="float-left fa-sm fas fa-shield-alt"></i>{{ match.teamB[0].displayName }}</span>
                <span class="team_name"> <i class="float-left fa-sm fas fa-futbol"></i>{{ match.teamB[1].displayName }}</span>
            </div>
        </div>
    </div>
</div>
<div class="section">
    <div class="table">
        <div class="table_line" *ngFor="let set of match.sets">
            <div class="table_cell table_cell-names">{{ set.teamA.defense.displayName }} / {{ set.teamA.offense.displayName }}</div>
            <div class="table_cell table_cell-score" [class.dark]="set.teamA.score > set.teamB.score">
                {{ set.teamA.score }}
            </div>
            <div class="table_cell table_cell-score" [class.dark]="set.teamA.score < set.teamB.score">
                {{ set.teamB.score }}
            </div>
            <div class="table_cell table_cell-names">{{ set.teamB.defense.displayName }} / {{ set.teamB.offense.displayName }}</div>
        </div>
    </div>
</div>
