<div class="d-flex align-items-center flex-column">
    <div class="team-grid" *ngIf="teamA && teamB">
        <div class="team-name col-span">
            {{ 'START_MATCH_SET.TEAM_A' | translate }}
            <span *ngIf="expectedResult" [matTooltip]="'START_MATCH_SET.ELO_OUTCOME_TOOLTIP' | translate"
                >({{ expectedResult.expectedOutcome * 100 | number: '.0-0' }} %)</span
            >
        </div>

        <div class="d-flex align-items-center justify-content-center flex-column box box-offence">
            <div class="icon"></div>
            <div class="role">{{ 'START_MATCH_SET.OFFENSE' | translate }}</div>
            <div class="name">{{ teamA.offense.displayName }}</div>
        </div>

        <div class="align-self-center btn-swap">
            <button type="button" mat-mini-fab color="primary" (click)="switchRole(teamA)" [disabled]="!canASwitchRoles">
                <mat-icon>swap_horiz</mat-icon>
            </button>
        </div>

        <div class="d-flex align-items-center justify-content-center flex-column box box-defense">
            <div class="icon"></div>
            <div class="role">{{ 'START_MATCH_SET.DEFENSE' | translate }}</div>
            <div class="name">{{ teamA.defense.displayName }}</div>
        </div>

        <div class="align-self-center btn-swap fa-rotate-90" *ngIf="canSwitchBetweenTeams">
            <button type="button" mat-mini-fab color="primary" (click)="switchTeam(1)">
                <mat-icon>swap_horiz</mat-icon>
            </button>
        </div>
        <div class="align-self-center team-name" [class.col-span]="!canSwitchBetweenTeams">
            {{ 'START_MATCH_SET.TEAM_B' | translate }}
            <span [matTooltip]="'START_MATCH_SET.ELO_OUTCOME_TOOLTIP' | translate" *ngIf="expectedResult"
                >({{ (1 - expectedResult.expectedOutcome) * 100 | number: '.0-0' }} %)</span
            >
        </div>
        <div class="align-self-center btn-swap fa-rotate-90" *ngIf="canSwitchBetweenTeams">
            <button type="button" mat-mini-fab color="primary" (click)="switchTeam(2)">
                <mat-icon>swap_horiz</mat-icon>
            </button>
        </div>

        <div class="d-flex align-items-center justify-content-center flex-column box box-defense">
            <div class="icon"></div>
            <div class="role">{{ 'START_MATCH_SET.DEFENSE' | translate }}</div>
            <div class="name">{{ teamB.defense.displayName }}</div>
        </div>

        <div class="align-self-center btn-swap">
            <button class="mx-2" type="button" mat-mini-fab color="primary" (click)="switchRole(teamB)" [disabled]="!canBSwitchRoles">
                <mat-icon>swap_horiz</mat-icon>
            </button>
        </div>

        <div class="d-flex align-items-center justify-content-center flex-column box box-offence">
            <div class="icon"></div>
            <div class="role">{{ 'START_MATCH_SET.OFFENSE' | translate }}</div>
            <div class="name">{{ teamB.offense.displayName }}</div>
        </div>
    </div>

    <div class="d-flex flex-column align-items-center justify-content-center my-4 gap-2">
        <button *ngIf="cancelInterval" class="btn-start" mat-raised-button color="primary" (click)="continue(switchPlayersSwal)">
            {{ 'START_MATCH_SET.START' | translate }}
        </button>

        <button *ngIf="match?.sets?.length > 0" mat-raised-button color="accent" [swal]="endMatchSwal">
            {{ 'START_MATCH_SET.END_MATCH' | translate }}
        </button>
    </div>
</div>

<swal
    #endMatchSwal
    [title]="'START_MATCH_SET.END_MATCH_CONFIRM_TITLE' | translate"
    [text]="'START_MATCH_SET.END_MATCH_CONFIRM_TEXT' | translate"
    [showCancelButton]="true"
    [cancelButtonText]="'START_MATCH_SET.END_MATCH_CONFIRM_CANCEL' | translate"
    [confirmButtonText]="'START_MATCH_SET.END_MATCH_CONFIRM_CONFIRM' | translate"
    [confirmButtonColor]="'#5ed7a2'"
    [icon]="'question'"
    (confirm)="endMatch()"
>
</swal>

<swal
    #switchPlayersSwal
    [title]="'START_MATCH_SET.ROLE_SWITCH_CONFIRM_TITLE' | translate"
    [text]="'START_MATCH_SET.ROLE_SWITCH_CONFIRM_TEXT' | translate: { max: settings.maximumPositionSwitchingDuringSet }"
    [showCancelButton]="true"
    [cancelButtonText]="'COMMON.CANCEL' | translate"
    [confirmButtonText]="'COMMON.OK' | translate"
    [confirmButtonColor]="'#5ed7a2'"
    [icon]="'question'"
>
</swal>
