<div class="card">
    <div class="card-body">
        @if (attachment().isImage) {
            <mat-icon class="upload-icon">image</mat-icon>
        } @else {
            <mat-icon class="upload-icon">smart_display</mat-icon>
        }
        <mat-progress-spinner class="example-margin" [mode]="'determinate'" [value]="attachment().progress"> </mat-progress-spinner>
        <div class="text-themed filename mt-2">{{ attachment().name }}</div>
    </div>
</div>
