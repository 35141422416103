import { Component, input } from '@angular/core';

export interface UploadingAttachment {
    isImage: boolean;
    name: string;
    progress: number;
}

@Component({
    selector: 'app-uploading-comment-attachment',
    templateUrl: './uploading-comment-attachment.component.html',
    styleUrl: './uploading-comment-attachment.component.scss',
})
export class UploadingCommentAttachmentComponent {
    public readonly attachment = input.required<UploadingAttachment>();
}
