import { Component, Input } from '@angular/core';
import { GetMatchModelExt } from 'core/entities';
import { GetMatchSetModel, SetScoreGoalRole } from 'models';

interface IGoal {
    playerA?: string;
    playerB?: string;
    role: SetScoreGoalRole;
    score?: string;
    scoreA?: number;
    scoreB?: number;
    elapsedSeconds?: number;
}

@Component({
    selector: 'app-match-box-goals',
    templateUrl: './match-box-goals.component.html',
    styleUrls: ['./match-box-goals.component.scss'],
})
export class MatchBoxGoalsComponent {
    public set: GetMatchSetModel;

    public setScoreGoalRole = SetScoreGoalRole;

    public goals: Array<IGoal>;

    private _match: GetMatchModelExt;
    @Input() set match(value: GetMatchModelExt) {
        this._match = value;
        this.setSet();
    }

    private _selectedSet: number;
    @Input() set selectedSet(value: number) {
        this._selectedSet = value;
        this.setSet();
    }

    private setSet() {
        const set = this._match?.sets[this._selectedSet];
        if (!set) {
            return;
        }
        this.goals = set.goals.map((goal, ix, self) => {
            const res: IGoal = {
                elapsedSeconds: goal.elapsedSeconds,
                role: goal.role,
            };
            res.playerA = this._match.players.find(
                (x) =>
                    x.idPlayer === goal.idPlayer &&
                    (set.idPlayerTeamADefense === goal.idPlayer || set.idPlayerTeamAOffense === goal.idPlayer),
            )?.displayName;
            res.playerB = this._match.players.find(
                (x) =>
                    x.idPlayer === goal.idPlayer &&
                    (set.idPlayerTeamBDefense === goal.idPlayer || set.idPlayerTeamBOffense === goal.idPlayer),
            )?.displayName;

            const scoreA = self.filter(
                (x, filterIx) => filterIx <= ix && (x.idPlayer === set.idPlayerTeamADefense || x.idPlayer === set.idPlayerTeamAOffense),
            ).length;
            const scoreB = self.filter(
                (x, filterIx) => filterIx <= ix && (x.idPlayer === set.idPlayerTeamBDefense || x.idPlayer === set.idPlayerTeamBOffense),
            ).length;
            res.scoreA = scoreA;
            res.scoreB = scoreB;
            res.score = `${scoreA}:${scoreB}`;

            return res;
        });
    }
}
