import { Component, Input } from '@angular/core';
import { GetMatchModelExt } from 'core/entities';
import { GetMatchPlayerModel, GetMatchSetModel, MatchTeam } from 'models';

@Component({
    selector: 'app-match-box-header-two-against-two',
    templateUrl: './match-box-header-two-against-two.component.html',
    styleUrls: ['../match-box.component.scss'],
})
export class MatchBoxHeaderTwoAgainstTwoComponent {
    public expA = 0;
    public expB = 0;
    public leftLinePercent = 0;
    public rightLinePercent = 0;
    private _match: GetMatchModelExt;

    public teamAWinnedSets = 0;
    public teamBWinnedSets = 0;

    @Input() public set match(value: GetMatchModelExt) {
        this._match = value;
        if (value) {
            this.expA = value.players.find((p) => p.team === MatchTeam.A).experiencePoints;
            this.expB = value.players.find((p) => p.team === MatchTeam.B).experiencePoints;
        }

        const teamAGoals = value.sets.reduce((acc, val) => acc + val.scoreTeamA, 0);
        const teamBGoals = value.sets.reduce((acc, val) => acc + val.scoreTeamB, 0);
        const totalGoals = teamAGoals + teamBGoals;
        this.leftLinePercent = Math.round((teamAGoals / totalGoals) * 100);
        this.rightLinePercent = 100 - this.leftLinePercent;

        this.teamAWinnedSets = value.sets.filter((x) => x.scoreTeamA > x.scoreTeamB).length;
        this.teamBWinnedSets = value.sets.filter((x) => x.scoreTeamA < x.scoreTeamB).length;
    }

    public get match() {
        return this._match;
    }

    public getRoleIcon(set: GetMatchSetModel, player: GetMatchPlayerModel) {
        if (set.idPlayerTeamADefense === player.idPlayer || set.idPlayerTeamBDefense === player.idPlayer) {
            return 'fa-shield-alt';
        } else {
            return 'fa-futbol';
        }
    }
}
