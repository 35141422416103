import { Injectable } from '@angular/core';
import { AbstractControlOptions, AsyncValidatorFn, UntypedFormBuilder, ValidatorFn } from '@angular/forms';
import { FormArray, FormControl, FormGroup } from './typed-controls';

type FormBuilderConfig<T> = Partial<{ [P in keyof T]: FormBuilderValue<T[P]> }>;
type ValidatorsConfig = ValidatorFn | Array<ValidatorFn>;
type AsyncValidatorsConfig = AsyncValidatorFn | Array<AsyncValidatorFn>;
type ControlBuilder<T> =
    | [value?: T | { value: T; disabled: boolean }, validators?: ValidatorsConfig, asyncValidators?: AsyncValidatorsConfig]
    | T;
type FormBuilderValue<T> =
    T extends Array<infer U>
        ? U extends string
            ? ControlBuilder<Array<string>>
            : U extends number
              ? ControlBuilder<Array<number>>
              : U extends boolean
                ? ControlBuilder<Array<boolean>>
                : FormArray<U>
        : T extends string
          ? ControlBuilder<string>
          : T extends number
            ? ControlBuilder<number>
            : T extends boolean
              ? ControlBuilder<boolean>
              : FormGroup | ControlBuilder<T>;

@Injectable({ providedIn: 'root' })
export class TypedFormBuilder {
    constructor(private readonly ngFormBuilder: UntypedFormBuilder) {}

    public group<T = any>(config: FormBuilderConfig<T>, options?: AbstractControlOptions) {
        return this.ngFormBuilder.group(config, options) as FormGroup<T>;
    }

    public array<T = any>(
        config: Array<FormBuilderConfig<T>>,
        validatorsOrOpts?: ValidatorsConfig | AbstractControlOptions,
        asyncValidators?: AsyncValidatorsConfig,
    ) {
        return this.ngFormBuilder.array(config, validatorsOrOpts, asyncValidators) as FormArray<T>;
    }

    public control<T = any>(formState: T) {
        return this.ngFormBuilder.control(formState) as FormControl<T>;
    }
}
