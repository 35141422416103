import { Component, Input } from '@angular/core';
import { GameMode, MatchPlayer, MatchSet } from 'models';
import { IMatch } from 'store/matches/matches.model';

@Component({
    selector: 'app-ongoing-match-box',
    templateUrl: './ongoing-match-box.component.html',
    styleUrls: ['../match-box/match-box.component.scss'],
})
export class OngoingMatchBoxComponent {
    public gameMode = GameMode;
    public pointsTeamA: number;
    public pointsTeamB: number;

    private _match: IMatch;

    public selectedSet = -1;

    @Input() first = false;

    @Input() set match(value: IMatch) {
        this._match = value;
        const sets = [...value.sets];
        sets.pop();
        this.pointsTeamA = sets.filter((x) => x.teamA.score > x.teamB.score).length;
        this.pointsTeamB = sets.filter((x) => x.teamB.score > x.teamA.score).length;
        this.selectedSet = value.sets.length - 1;
    }

    get match() {
        return this._match;
    }

    getRoleIcon(set: MatchSet, player: MatchPlayer) {
        if (set.teamA.offense.id === player.id || set.teamB.offense.id === player.id) {
            return 'fa-futbol';
        } else {
            return 'fa-shield-alt';
        }
    }
}
