<div class="grid grid-achievements mat-elevation-z8">
    <ng-container *ngFor="let achievement of achievements">
        <div class="grid-row">
            <img class="img p-2" width="50" [src]="achievement.trophy | trophyPath" />
            <div>{{ achievement.playerDisplayName }}</div>
            <div>{{ achievement.seasonName }}</div>
            <a class="text-decoration-none" [routerLink]="['/achievements/', achievement.id]">{{
                'ACHIEVEMENTS.' + achievement.group | uppercase | translate: { targetValue: achievement.targetValue }
            }}</a>
        </div>
    </ng-container>
</div>
