import { Injectable } from '@angular/core';

export const storageKeys = {
    lang: 'l',
    version: 'v',
    showReleaseNotesStrip: 's',
    theme: 't',
    activeMatch: 'm',
    activeMatchTimers: 'mt',
    browserId: 'bid',
    dontShowPushAlert: 'dspa',
    refreshToken: 'rt',
};

class InMemoryStorage implements Storage {
    [name: string]: any;
    get length() {
        return Object.keys(this.#storage).length;
    }
    clear(): void {
        this.#storage = {};
    }
    #storage: Record<string, string> = {};

    getItem(key: string): string {
        return this.#storage[key] || undefined;
    }
    key(): string {
        return null;
    }
    removeItem(key: string): void {
        delete this.#storage[key];
    }

    setItem(key: string, value: string): void {
        this.#storage[key] = value;
    }
}

@Injectable({
    providedIn: 'root',
})
export class BrowserStorageService {
    public readonly localStorageAvaiable: boolean;
    public readonly sessionStorageAvaiable: boolean;

    #memoryStorage = new InMemoryStorage();

    public get local() {
        return this.localStorageAvaiable ? window.localStorage : this.#memoryStorage;
    }
    public get session() {
        return this.sessionStorageAvaiable ? window.sessionStorage : this.#memoryStorage;
    }

    constructor() {
        if ('localStorage' in window) {
            try {
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                const l = localStorage.length;
                this.localStorageAvaiable = true;
            } catch {
                this.localStorageAvaiable = false;
            }
        }
        if ('sessionStorage' in window) {
            try {
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                const l = sessionStorage.length;
                this.sessionStorageAvaiable = true;
            } catch {
                this.sessionStorageAvaiable = false;
            }
        }
    }
}
