import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store, select } from '@ngrx/store';
import { BrowserStorageService, storageKeys } from 'core/browser-storage.service';
import { Session } from 'core/session.service';
import { SignalRService } from 'core/signalr.service';
import {
    CreateMatchModel,
    GameMode,
    MatchExpectedOutcomeModel,
    MatchTeamResultModel,
    SavedMatchCountModel,
    SavedMatchInfoModel,
    SavedMatchModel,
} from 'models';
import { of } from 'rxjs';
import { map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { AppState } from 'store';
import {
    clearActiveMatch,
    clearActiveMatchSuccess,
    createActiveMatch,
    createActiveMatchSuccess,
    loadSavedMatches,
    loadSavedMatchesCount,
    loadSavedMatchesCountSuccess,
    loadSavedMatchesSuccess,
    restoreActiveMatch,
    restoreActiveMatchSuccess,
    restoreSavedMatch,
    restoreSavedMatchSuccess,
    saveActiveMatch,
    saveOngoingActiveMatch,
    saveOngoingActiveMatchSuccess,
    setExpectedOutcome,
    setExpectedOutcomeSuccess,
} from './matches.actions';
import { IMatch, IMatchTimers, PlayersPair } from './matches.model';

@Injectable()
export class MatchesEffects {
    createActiveMatch$ = createEffect(() =>
        this.actions$.pipe(
            ofType(createActiveMatch),
            switchMap((action) => {
                if (action.matchSettings.gameMode === GameMode.TwoAgainstTwo) {
                    return this.http
                        .get<MatchTeamResultModel>('/api/matches/create-teams', {
                            params: {
                                idPlayer1: String(action.teamA[0].id),
                                idPlayer2: String(action.teamA[1].id),
                                idPlayer3: String(action.teamB[0].id),
                                idPlayer4: String(action.teamB[1].id),
                            },
                        })
                        .pipe(
                            map((matchTeam) =>
                                createActiveMatchSuccess({
                                    teamA: matchTeam.teamA as PlayersPair,
                                    teamB: matchTeam.teamB as PlayersPair,
                                    uniqueId: action.uniqueId,
                                    matchSettings: action.matchSettings,
                                }),
                            ),
                        );
                } else {
                    // make sure logged player is always in teamB
                    if (action.teamA.some((x) => x.id === this.session.user.idPlayer)) {
                        const temp = action.teamA;
                        action.teamA = action.teamB;
                        action.teamB = temp;
                    }
                    return of(
                        createActiveMatchSuccess({
                            teamA: action.teamA,
                            teamB: action.teamB,
                            uniqueId: action.uniqueId,
                            matchSettings: action.matchSettings,
                        }),
                    );
                }
            }),
        ),
    );

    saveMatch$ = createEffect(() =>
        this.actions$.pipe(
            ofType(saveActiveMatch),
            withLatestFrom(this.store$.pipe(select((x) => x.matches))),
            switchMap(([, matches]) => {
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                const { restored, version, settings, ...match } = matches.active;
                const active: CreateMatchModel = { ...match, elapsedSeconds: matches.activeTimers.fromMatchStart, settings };
                return this.http.post<any>('/api/matches', active).pipe(map(() => clearActiveMatch({ uniqueId: matches.active.uniqueId })));
            }),
        ),
    );

    setExpectedOutcoma$ = createEffect(() =>
        this.actions$.pipe(
            ofType(setExpectedOutcome),
            switchMap(({ players }) => {
                const p = new HttpParams({
                    fromObject: {
                        p1: String(players[0]),
                        p2: String(players[1]),
                        p3: String(players[2]),
                        p4: String(players[3]),
                    },
                });
                return this.http
                    .get<MatchExpectedOutcomeModel>('/api/teams/expected-outcome', {
                        params: p,
                    })
                    .pipe(map((x) => setExpectedOutcomeSuccess({ expectedOutcome: x })));
            }),
        ),
    );

    restoreActiveMatch$ = createEffect(() =>
        this.actions$.pipe(
            ofType(restoreActiveMatch),
            map(() => {
                const match = this.storage.session.getItem(storageKeys.activeMatch);
                const timers = this.storage.session.getItem(storageKeys.activeMatchTimers);
                if (!!match && !!timers) {
                    const parsedMatch = JSON.parse(match) as IMatch;
                    const parsedTimers = JSON.parse(timers) as IMatchTimers;
                    if (parsedMatch.version !== 1) {
                        // eslint-disable-next-line no-console
                        console.error('Match version is not supported', parsedMatch.version);
                        return restoreActiveMatchSuccess({ match: null, timers: null });
                    } else {
                        return restoreActiveMatchSuccess({ match: parsedMatch, timers: parsedTimers });
                    }
                } else {
                    return restoreActiveMatchSuccess({ match: null, timers: null });
                }
            }),
        ),
    );

    clearActiveMatch$ = createEffect(() =>
        this.actions$.pipe(
            ofType(clearActiveMatch),
            tap(({ uniqueId }) => {
                this.storage.session.removeItem(storageKeys.activeMatch);
                this.storage.session.removeItem(storageKeys.activeMatchTimers);
                this.signalR.invokeClearOngoingMatch(uniqueId);
            }),
            map((action) => clearActiveMatchSuccess({ uniqueId: action.uniqueId })),
        ),
    );

    loadSavedMatches$ = createEffect(() =>
        this.actions$.pipe(
            ofType(loadSavedMatches),
            switchMap(() => this.http.get<Array<SavedMatchInfoModel>>('/api/saved-matches')),
            map((res) => loadSavedMatchesSuccess({ values: res })),
        ),
    );

    loadSavedMatchesCount$ = createEffect(() =>
        this.actions$.pipe(
            ofType(loadSavedMatchesCount),
            switchMap(() => this.http.get<SavedMatchCountModel>('/api/saved-matches/count')),
            map((res) => loadSavedMatchesCountSuccess({ count: res.count })),
        ),
    );

    saveSavedMatche$ = createEffect(() =>
        this.actions$.pipe(
            ofType(saveOngoingActiveMatch),
            withLatestFrom(this.store$.pipe(select((x) => x.matches))),
            switchMap(([action, { active, activeTimers }]) =>
                this.http.post(`/api/saved-matches/`, {
                    name: action.name,
                    match: active,
                    timer: activeTimers,
                }),
            ),
            map(() => saveOngoingActiveMatchSuccess()),
        ),
    );

    restoreSavedMatche$ = createEffect(() =>
        this.actions$.pipe(
            ofType(restoreSavedMatch),
            switchMap(({ id }) => this.http.get<SavedMatchModel>(`/api/saved-matches/${id}`)),
            map((res) => restoreSavedMatchSuccess({ match: res })),
        ),
    );

    constructor(
        private readonly actions$: Actions,
        private store$: Store<AppState>,
        private readonly http: HttpClient,
        private readonly signalR: SignalRService,
        private readonly session: Session,
        private readonly storage: BrowserStorageService,
    ) {}
}
