import { Component, input } from '@angular/core';
import { EMPTY } from 'core/utils';
import { BlobMetadataModel } from 'models';

@Component({
    selector: 'app-comment-attachment',
    templateUrl: './comment-attachment.component.html',
    styleUrl: './comment-attachment.component.scss',
})
export class CommentAttachmentComponent {
    attachment = input.required<BlobMetadataModel>();

    onImageClick(image: HTMLImageElement) {
        image.requestFullscreen().catch(EMPTY);
    }
}
