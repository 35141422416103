import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { Season } from 'models';
import { AppState } from 'store';
import { changeSeason } from 'store/season/season.actions';

@Component({
    selector: 'app-season-select',
    templateUrl: './season-select.component.html',
    styleUrls: ['./season-select.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SeasonSelectComponent {
    @Input()
    public activeSeason: Season;

    @Input()
    public set seasons(value: Array<Season>) {
        if (!value) {
            this.main = [];
            this.older = [];
        } else {
            this.main = value.slice(0, 5);
            this.older = value.slice(5);
        }
    }

    public main: Array<Season> = [];
    public older: Array<Season> = [];

    constructor(private readonly store: Store<AppState>) {}

    public selectSeason(s: Season) {
        this.store.dispatch(changeSeason({ payload: s }));
    }
}
