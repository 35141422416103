import { FoosballTableType, TeamSettings } from 'models';

export function isSetWon(teamSettings: TeamSettings, scoreA: number, scoreB: number): boolean {
    if (teamSettings.foosballTableType === FoosballTableType.Classical) {
        return isSetWonOnClassicalTable(teamSettings, scoreA, scoreB);
    } else if (teamSettings.foosballTableType === FoosballTableType.CoinBased) {
        return isSetWonOnCoinBasedTable(teamSettings, scoreA, scoreB);
    }
    return false;
}

function isSetWonOnCoinBasedTable({ numberOfBalls }: TeamSettings, scoreA: number, scoreB: number): boolean {
    if (scoreA + scoreB === numberOfBalls) {
        return true;
    }
}

function isSetWonOnClassicalTable(
    { numberOfGoalsToWin, minimumGoalsDeltaToWin, maximumNumberOfGoals }: TeamSettings,
    scoreA: number,
    scoreB: number,
): boolean {
    if (scoreA >= numberOfGoalsToWin) {
        if (scoreA - scoreB >= minimumGoalsDeltaToWin) {
            return true;
        }
        if (maximumNumberOfGoals !== null && scoreA >= maximumNumberOfGoals) {
            return true;
        }
    }
    if (scoreB >= numberOfGoalsToWin) {
        if (scoreB - scoreA >= minimumGoalsDeltaToWin) {
            return true;
        }
        if (maximumNumberOfGoals !== null && scoreB >= maximumNumberOfGoals) {
            return true;
        }
    }
    return false;
}
