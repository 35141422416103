import { createAction, props } from '@ngrx/store';
import { IPlayer, IPlayerLevel, IPlayerWinRate, NamedPlayersTeamExt } from './players.model';

import { NamedPlayersTeam, Player, PlayerStatisticsCollectionModel, PlayerStatisticsModel, Season, TeamSettings } from 'models';

export const loadPlayers = createAction('[PLAYERS] Load', props<{ force: boolean }>());
export const loadPlayersSuccess = createAction('[PLAYERS] Load success', props<{ players: Array<IPlayer> }>());

export const loadPlayersStats = createAction('[PLAYERS] Load stats', props<{ season: Season }>());
export const loadPlayersStatsSuccess = createAction('[PLAYERS] Load stats success', props<{ stats: Array<PlayerStatisticsModel> }>());

export const loadPlayersStatsDetail = createAction('[PLAYERS] Load stats detail', props<{ idPlayer: number; season: Season }>());
export const loadPlayersStatsDetailSuccess = createAction(
    '[PLAYERS] Load stats detail success',
    props<{ stats: PlayerStatisticsCollectionModel }>(),
);

export const sortWinRate = createAction('[PLAYERS] sort winrate', props<{ col: keyof IPlayerWinRate; asc: boolean }>());

export const updatePlayer = createAction('[PLAYERS] Update', props<{ player: Player }>());

export const loadSettings = createAction('[PLAYERS] Load settings');
export const loadSettingsSuccess = createAction('[PLAYERS] Load settings success', props<{ settings: TeamSettings }>());
export const updateSettings = createAction('[PLAYERS] Update settings', props<{ settings: TeamSettings }>());

export const loadPlayerLevel = createAction('[Players] Load level info');
export const loadPlayerLevelSuccess = createAction('[Players] Load level info success', props<{ payload: IPlayerLevel }>());

export const loadNamedTeams = createAction('[PLAYERS] Load named teams', props<{ force: boolean }>());
export const clearNamedTeams = createAction('[PLAYERS] Clear named teams');
export const loadNamedTeamsSuccess = createAction(
    '[PLAYERS] Load named teams success',
    props<{ namedTeams: Array<NamedPlayersTeamExt> }>(),
);

export const createNamedTeam = createAction('[PLAYERS] Create named team', props<{ namedTeam: NamedPlayersTeam }>());
export const deleteNamedTeam = createAction('[PLAYERS] Delete named team', props<{ namedTeam: NamedPlayersTeam }>());

export const removeFromTeam = createAction('[PLAYERS] Remove from team', props<{ player: IPlayer }>());
