<nav class="bottom-menu d-xxl-none navbar sticky-bottom" *ngIf="showMenu">
    <div class="container-fluid menu-grid">
        <a
            class="menu-item d-flex flex-column align-items-center"
            routerLink="/dashboard"
            routerLinkActive="active"
            (click)="navigationClick()"
        >
            <mat-icon>dashboard</mat-icon>
            <span>{{ 'NAVBAR.DASHBOARD' | translate }}</span>
        </a>
        <a
            class="menu-item d-flex flex-column align-items-center"
            routerLink="/statistics"
            routerLinkActive="active"
            (click)="navigationClick()"
        >
            <mat-icon>leaderboard</mat-icon>
            <span>{{ 'NAVBAR.STATISTICS' | translate }}</span>
        </a>
        <span class="btn-add-match" (click)="addMatchModal()">
            <i class="material-icons">add</i>
        </span>
        <a
            class="menu-item d-flex flex-column align-items-center"
            routerLink="/achievements"
            routerLinkActive="active"
            (click)="navigationClick()"
        >
            <mat-icon>emoji_events</mat-icon>
            <span>{{ 'NAVBAR.ACHIEVEMENTS' | translate }}</span>
        </a>
        <a
            class="menu-item d-flex flex-column align-items-center"
            [class.active]="menuOpened"
            href="javascript:void(0)"
            routerLinkActive="active"
            (click)="menuClick()"
        >
            <mat-icon>menu</mat-icon>
            <span>{{ 'NAVBAR.MENU' | translate }}</span>
        </a>
    </div>
</nav>
