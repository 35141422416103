import { Component, Input } from '@angular/core';
import { GetMatchEloPointsModel } from 'models';

@Component({
    selector: 'app-match-box-elo-points',
    templateUrl: './match-box-elo-points.component.html',
    styleUrls: ['./match-box-elo-points.component.scss'],
})
export class MatchBoxEloPointsComponent {
    @Input() elo1: GetMatchEloPointsModel;
    @Input() elo2: GetMatchEloPointsModel;
    @Input() expectedOutcome: number;
}
