import { Directive, TemplateRef, ViewContainerRef, Input } from '@angular/core';
import { Session } from 'core/session.service';
import { TeamMemberRole } from 'models';

@Directive({
    selector: '[appIfInTeamRole]',
})
export class IfInTeamRoleDirective {
    @Input() set appIfInTeamRole(value: TeamMemberRole) {
        if (this.session.user.teamMemberRole === value) {
            this.viewContainer.createEmbeddedView(this.templateRef);
        } else {
            this.viewContainer.clear();
        }
    }

    constructor(
        private readonly templateRef: TemplateRef<any>,
        private readonly viewContainer: ViewContainerRef,
        private readonly session: Session,
    ) {}
}
