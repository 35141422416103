<h1 mat-dialog-title>{{ 'ADD_MATCH.INVITATION_MESSAGE' | translate }}</h1>
<div mat-dialog-content>
    <div class="d-flex flex-column">
        <mat-form-field appearance="outline" class="mt-1">
            <mat-label>{{ 'CREATE_MATCH_INVITATION.MESSAGE' | translate }}</mat-label>
            <input name="name" matInput [formControl]="form.get('message')" />
        </mat-form-field>

        <label for="maxOpenedDuration" class="text-center">{{
            'CREATE_MATCH_INVITATION.MAX_OPENED_DURATION' | translate: { value: this.form.get('maxOpenDuration').value }
        }}</label>

        <mat-slider min="1" max="120" step="1">
            <input id="maxOpenedDuration" name="maxOpenedDuration" matSliderThumb [formControl]="form.get('maxOpenDuration')" />
        </mat-slider>

        <div class="my-2 text-center">
            <div class="d-flex justify-content-center">
                <app-chip [selected]="allSelected()" (selectedChange)="selectAllChanged()" [id]="'chip2_all'">
                    {{ 'ADD_MATCH.SELECT_ALL' | translate }}
                </app-chip>
            </div>
        </div>

        <div class="row section">
            <app-chip
                *ngFor="let p of players; let ix = index"
                [selected]="p.selected"
                [disabled]="p.disabled"
                class="col-12 col-md-4"
                (selectedChange)="p.selected = !p.selected"
                [id]="'chip2_' + ix"
            >
                {{ p.displayName }}
            </app-chip>
        </div>
    </div>
</div>

<div mat-dialog-actions class="float-right">
    <button mat-button mat-dialog-close>{{ 'COMMON.CLOSE' | translate }}</button>
    <button mat-raised-button color="primary" [disabled]="saveDisabled()" (click)="onSave()">{{ 'COMMON.SEND' | translate }}</button>
</div>
