<h4 *ngIf="!isSwitching" mat-dialog-title>{{ 'NAVBAR.CHANGE_TEAM' | translate }}</h4>

<mat-dialog-content>
    <div *ngIf="!isSwitching">
        <div class="text-center">
            <div>Tvoje týmy</div>
            <ul class="list-group">
                <li
                    class="list-group-item"
                    [class.clickable]="team.id !== currentTeam"
                    *ngFor="let team of teams"
                    [class.active]="team.id === currentTeam"
                    (click)="selectTeam(team)"
                >
                    <div class="my-2">{{ team.name }}</div>
                </li>
            </ul>
        </div>
        <ul class="list-group text-center mt-3">
            <li class="list-group-item bg-success clickable" (click)="createNewTeam()">
                <div class="my-2"><i class="fas fa-user-plus"></i> {{ 'NAVBAR.CHANGE_TEAM_NEW_TEAM' | translate }}</div>
            </li>
        </ul>
    </div>

    <div class="spinner d-flex flex-column align-items-center" *ngIf="isSwitching">
        <mat-progress-spinner [mode]="'indeterminate'"></mat-progress-spinner>
        <h4 class="mt-3">{{ 'NAVBAR.SWITCHING_TEAMS' | translate }}</h4>
    </div>
</mat-dialog-content>
<div *ngIf="!isSwitching" mat-dialog-actions class="float-right">
    <button mat-button mat-dialog-close>{{ 'COMMON.CLOSE' | translate }}</button>
</div>
