<div mat-dialog-title class="text-center">
    <div class="app-title">Fooswars {{ 'ABOUT.VERSION' | translate }} {{ version }}</div>
    <div class="d-flex flex-row justify-content-center gap-3 sub-title">
        <a href="mailto:info@jandeu.cz">info&#64;jandeu.cz</a>
        <a href="https://fooswars.com/privacy-policy">Privacy policy</a>
        <a href="https://fooswars.com/terms-and-conditions">Terms and conditions</a>
    </div>
    <div *ngIf="about" class="text-muted">
        <small>{{ 'ABOUT.STACK' | translate: about }}</small>
    </div>
</div>

<mat-dialog-content class="scrollbar">
    <div class="release-notes" [innerHTML]="notes$ | async"></div>
</mat-dialog-content>

<mat-dialog-actions align="end">
    <button type="button" color="primary" mat-dialog-close mat-raised-button>
        {{ 'ABOUT.CLOSE_BTN' | translate }}
    </button>
</mat-dialog-actions>
