<ng-template #emptyScoreboard>
    <div *ngIf="items && items.length === 0" class="d-flex flex-column justify-content-center align-items-center empty-message">
        <h3>{{ 'DASHBOARD.EMPTY_TEAMS_SCOREBOARD' | translate }}</h3>
    </div>
</ng-template>

<div class="fsTable scoreboard mat-elevation-z8">
    <div class="fsTable_header scoreboard_header">
        <div class="fsTable_cell-head scoreboard_cell-name">
            {{ 'DASHBOARD.TOP_TEAMS' | translate }}
        </div>
        <div class="fsTable_cell-head scoreboard_cell-matches">
            {{ 'DASHBOARD.MATCHES' | translate }}
        </div>
        <div class="fsTable_cell-head scoreboard_cell-winsPerc">
            {{ 'DASHBOARD.WINS_PERCENT' | translate }}
        </div>
        <div class="fsTable_cell-head scoreboard_cell-wins">{{ 'DASHBOARD.POINTS' | translate }}</div>
    </div>
    <div class="box-wrapper scrollbar" id="style-5" [style.max-height.px]="height" *ngIf="items?.length; else emptyScoreboard">
        <div
            class="fsTable_line scoreboard_line"
            [class.first]="ix === 0"
            [class.second]="ix === 1"
            [class.third]="ix === 2"
            *ngFor="let t of items; let ix = index"
        >
            <div class="fsTable_cell scoreboard_cell-name">
                <span *ngIf="!t.namedTeamName">{{ ix + 1 }}. {{ t.player1 }} - {{ t.player2 }}</span>
                <span [matTooltip]="t.player1 + ' - ' + t.player2" *ngIf="t.namedTeamName">{{ ix + 1 }}. {{ t.namedTeamName }}</span>
            </div>
            <div class="fsTable_cell scoreboard_cell-matches">{{ t.winsCount }} / {{ t.matchesCount }}</div>
            <div class="fsTable_cell scoreboard_cell-wins">
                {{ t.winPercentage }}
            </div>
            <div
                class="fsTable_cell scoreboard_cell-winsPerc"
                [matTooltip]="t.pointsAvarage | number: '1.0-5' : 'cs'"
                matTooltipPosition="above"
                matTooltipTouchGestures="off"
            >
                {{ t.pointsAvarage | number: '1.0-2' }}
            </div>
        </div>

        <div
            [matTooltip]="'DASHBOARD.MATCHBOX_TRESHOLD' | translate: { threshold: t.remainsForQualification }"
            class="fsTable_line scoreboard_line text-muted"
            matTooltipTouchGestures="off"
            *ngFor="let t of notQualified"
        >
            <div class="fsTable_cell scoreboard_cell-name text-decoration-line-through">
                <span *ngIf="!t.namedTeamName">{{ t.player1 }} - {{ t.player2 }}</span>
                <span [matTooltip]="t.player1 + ' - ' + t.player2" *ngIf="t.namedTeamName">{{ t.namedTeamName }}</span>
            </div>
            <div class="fsTable_cell scoreboard_cell-matches">{{ t.winsCount }} / {{ t.matchesCount }}</div>
            <div class="fsTable_cell scoreboard_cell-wins">
                {{ t.winPercentage }}
            </div>
            <div
                class="fsTable_cell scoreboard_cell-winsPerc"
                [matTooltip]="t.pointsAvarage | number: '1.0-5' : 'cs'"
                matTooltipPosition="above"
            >
                {{ t.pointsAvarage | number: '1.0-2' }}
            </div>
        </div>
    </div>
</div>
