// eslint-disable-next-line @typescript-eslint/naming-convention
export function _(key: string): string {
    return key;
}

export const deepClone = (inObject: object) => {
    let value, key;

    if (typeof inObject !== 'object' || inObject === null) {
        return inObject; // Return the value if inObject is not an object
    }

    // Create an array or object to hold the values
    const outObject = Array.isArray(inObject) ? [] : {};

    for (key in inObject) {
        if (inObject.hasOwnProperty(key)) {
            value = inObject[key];

            // Recursively (deep) copy for nested objects, including arrays
            outObject[key] = deepClone(value);
        }
    }

    return outObject;
};

export function b64toBlob(dataURI: string, type?: string) {
    let contentType = dataURI.split(';')[0];
    if (contentType.startsWith('data:')) {
        contentType = contentType.substring(5);
    }
    const byteString = atob(dataURI.split(',')[1]);
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);

    for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: type || contentType });
}

export function getRandomInt(min: number, max: number) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
}

/**
 * Generate unique IDs. Generates a string guaranteed to be unique when compared
 *  to other strings generated by this function.
 */
export function createUniqueId() {
    return crypto.randomUUID();
}

export function removeDiacritics(input: string) {
    if (!input) {
        return input;
    }
    return input
        .normalize('NFD')
        .replace(/\p{Diacritic}/gu, '')
        .toLowerCase();
}

export const EMPTY = (err) => {
    if (err) {
        // eslint-disable-next-line no-console
        console.error(err);
    }
};
