<mat-dialog-content>
    <div class="text-center">
        <h3>Registrace k notifikacím</h3>
    </div>

    <ul class="list-group">
        <app-list-check-item [checked]="supportedInBrowser" [text]="'Podpora notifikací v prohlížeči'"></app-list-check-item>
        <app-list-check-item [checked]="serviceWorkerEnabled" [text]="'Zapnutý a nainstalovaný service worker'"></app-list-check-item>
        <app-list-check-item [checked]="permissionGranted" [text]="'Získáno oprávnění od uživatele'"></app-list-check-item>
        <app-list-check-item [checked]="subscriptionReceived" [text]="'Získána subsckripce'"></app-list-check-item>
        <app-list-check-item [checked]="subscriptionSend" [text]="'Zaregistrována subskribce'"></app-list-check-item>
    </ul>
</mat-dialog-content>
<div mat-dialog-actions class="float-right">
    <button mat-button mat-dialog-close>{{ 'COMMON.CLOSE' | translate }}</button>
</div>
