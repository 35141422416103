import { Action, createReducer, on } from '@ngrx/store';
import { removeDiacritics } from 'core/utils';
import { AutobalanceMode, FoosballTableType, GameMode, PlayerSelectionMode, TeamsScoreboardMode } from 'models';
import {
    clearNamedTeams,
    loadNamedTeamsSuccess,
    loadPlayerLevelSuccess,
    loadPlayersStatsDetail,
    loadPlayersStatsDetailSuccess,
    loadPlayersStatsSuccess,
    loadPlayersSuccess,
    loadSettingsSuccess,
    sortWinRate,
} from './players.actions';
import { IPlayersState } from './players.model';

const initialState: IPlayersState = {
    all: [],
    stats: [],
    statsDetail: null,
    teamSettings: {
        autobalanceMode: AutobalanceMode.None,
        autoSwitchPlayersOnNextSet: true,
        idTeam: 0,
        numberOfGoalsToWin: 10,
        numberOfWinnedSetsToWin: 2,
        showAditionalPositionImages: false,
        showParagonLevel: false,
        playerSelectionMode: PlayerSelectionMode.Players,
        teamsScoreboardMode: TeamsScoreboardMode.Default,
        additionalMedalsSettings: [],
        scoreEnteringMode: null,
        foosballTableType: FoosballTableType.Classical,
        minimumGoalsDeltaToWin: 1,
        gameMode: GameMode.TwoAgainstTwo,
        numberOfBalls: 10,
        team: null,
        allowPositionSwitchingDuringSet: true,
        maximumNumberOfGoals: null,
        maximumPositionSwitchingDuringSet: null,
    },
    levelInfo: null,
    namedTeams: [],
};

const reducer = createReducer(
    initialState,
    on(loadPlayersStatsDetail, (state) => ({ ...state, statsDetail: null }) as IPlayersState),
    on(loadPlayersSuccess, (state, { players }) => {
        players.forEach((x) => (x.normalizedDisplayName = removeDiacritics(x.displayName)));
        return { ...state, all: players };
    }),
    on(loadPlayersStatsSuccess, (state, { stats }) => ({ ...state, stats: stats })),
    on(loadPlayersStatsDetailSuccess, (state, { stats }) => ({ ...state, statsDetail: stats })),
    on(loadSettingsSuccess, (state, { settings }) => ({ ...state, teamSettings: { ...state.teamSettings, ...settings } })),
    on(loadNamedTeamsSuccess, (state, { namedTeams }) => {
        namedTeams.forEach((x) => (x.normalizedTeamName = removeDiacritics(x.teamName)));
        return { ...state, namedTeams: namedTeams };
    }),
    on(loadPlayerLevelSuccess, (state, { payload }) => ({ ...state, levelInfo: payload })),
    on(clearNamedTeams, (state) => ({ ...state, namedTeams: [] }) as IPlayersState),
    on(sortWinRate, (state, { asc, col }) => {
        if (state.statsDetail) {
            const n = { ...state.statsDetail };
            if (col === 'displayName') {
                n.winRate = n.winRate.sort((a, b) => a.displayName.localeCompare(b.displayName));
            } else {
                n.winRate = n.winRate.sort((a, b) => a[col] - b[col]);
            }
            if (!asc) {
                n.winRate = n.winRate.reverse();
            }

            return { ...state, statsDetail: n };
        }
        return state;
    }),
);

export function playersReducer(state: IPlayersState = initialState, action: Action): IPlayersState {
    return reducer(state, action);
}
