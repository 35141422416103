<div class="">
    @if (attachment().contentType.startsWith('image')) {
        <img
            [src]="attachment().path"
            class="img-fluid clickable"
            [title]="attachment().name"
            [width]="attachment().width"
            [height]="attachment().height"
            #image
            (click)="onImageClick(image)"
        />
    } @else {
        <video width="100%" controls>
            <source [src]="attachment().path" [type]="attachment().contentType" />
        </video>
    }
</div>
