<div class="d-flex flex-row">
    <div class="avatar-wrapper">
        <div *ngIf="comment.idParent" class="parent-line"></div>
        <img [attr.src]="comment.idPlayer | avatarUrl" class="comment-avatar mt-2 mx-2" [attr.alt]="comment.playerDisplayName" />
        <div #replyLine *ngIf="comment.replies.length" class="reply-line"></div>
    </div>

    <div class="d-flex flex-column w-100">
        <div class="card" (mouseenter)="showEmoChooser = true" (mouseleave)="showEmoChooser = false">
            <div
                class="card emo-chooser d-flex flex-row"
                *ngIf="showEmoChooser"
                (mouseenter)="emoChooserMouseOver = true"
                (mouseleave)="emoChooserMouseOver = false"
            >
                <span
                    (mouseenter)="hoveredEmo = e"
                    (mouseleave)="hoveredEmo = null"
                    [class.dimmed]="emoChooserMouseOver && hoveredEmo !== e"
                    *ngFor="let e of emojies"
                    (click)="addCommentEmoji(comment.id, e.type)"
                    >{{ e.text }}</span
                >
            </div>
            <div class="d-flex flex-column">
                <div class="card-body pb-0">
                    <div class="d-flex">
                        <div class="d-flex flex-row align-items-center">
                            <strong class="player-name">{{ comment.playerDisplayName }}</strong>
                        </div>

                        <div class="ms-auto">
                            <ng-container *ngFor="let e of emojies">
                                <span *ngIf="e.count" [matTooltip]="e.tooltip" class="emo"
                                    >{{ e.text }}<span [class.color-blue]="userReacted(e)" class="num">{{ e.count }}</span></span
                                >
                            </ng-container>
                        </div>
                    </div>
                    @if (editMode) {
                        <app-upsert-comment [idMatch]="comment.idMatch" [comment]="comment" [idParentComment]="comment.idParent">
                        </app-upsert-comment>
                    } @else {
                        <p class="card-text" [class.mb-3]="!comment.attachments.length" [innerHTML]="comment.text | preserveNewline"></p>
                    }
                    <img
                        *ngIf="comment.imageUrl"
                        [src]="comment.imageUrl"
                        class="mb-1 card-img-top comment-img"
                        [alt]="comment.imageUrl"
                        alt="giph"
                    />
                </div>
            </div>
            <div class="row m-2" *ngIf="comment.attachments.length && !editMode">
                <app-comment-attachment
                    *ngFor="let attachment of comment.attachments"
                    [attachment]="attachment"
                    [ngClass]="{
                        'col-lg-6': attachment.contentType.startsWith('image') && comment.attachments.length !== 1,
                        'col-lg-12': attachment.contentType.startsWith('video') || comment.attachments.length === 1,
                    }"
                ></app-comment-attachment>
            </div>
            <app-upsert-comment
                [style.margin-left.px]="level() * 30"
                *ngIf="showReply"
                [idMatch]="comment.idMatch"
                [idParentComment]="comment.id"
                [focusOnInit]="true"
                (sent)="showReply = false"
                class="m-2"
            ></app-upsert-comment>
        </div>
        <div class="d-flex flex-row justify-content-between p-1 player-name">
            <div class="d-flex flex-row gap-2">
                <span class="text-muted clickable" *ngIf="level() < 2" (click)="onReplyCommentClick()">{{
                    'MATCH_BOX.COMMENT_REPLY' | translate
                }}</span>
                <span class="text-muted clickable" *ngIf="comment.idPlayer === idPlayer" (click)="onEditCommentClick()">{{
                    'MATCH_BOX.COMMENT_EDIT' | translate
                }}</span>
                <span
                    class="text-muted clickable"
                    [swal]="deleteCommentSwal"
                    *ngIf="comment.idPlayer === idPlayer && comment.replies.length === 0"
                    >{{ 'MATCH_BOX.COMMENT_DELETE' | translate }}</span
                >
                <swal
                    #deleteCommentSwal
                    (confirm)="deleteComment(comment.id)"
                    [title]="'MATCH_BOX.DELETRE_COMMENT_SWAL_TTITLE' | translate"
                    [showCancelButton]="true"
                    [focusConfirm]="true"
                    [backdrop]="false"
                    icon="question"
                >
                </swal>
            </div>

            <span class="text-muted player-name" [matTooltip]="comment.createdAt | moment: 'DATETIME_SHORT'">{{
                comment.createdAt | moment: 'fromNow'
            }}</span>
        </div>

        <app-comment
            #childComment
            *ngFor="let reply of comment.replies; let last = last"
            [comment]="reply"
            [level]="level() + 1"
        ></app-comment>
    </div>
</div>
