import * as matchesActions from './matches/matches.actions';
import * as errorActions from './error/error.actions';
import * as playersActions from './players/players.actions';
import * as playersSelectors from './players/players.selectors';
import * as seasonActions from './season/season.actions';
import * as sidebarActions from './sidebar/sidebar.actions';
import * as statisticsActions from './statistics/statistics.actions';
import * as userActions from './user/user.actions';
import * as scoreboardsActions from './scoreboards/scoreboards.actions';
import * as scoreboardsSelectors from './scoreboards/scoreboards.selectors';
import * as achievementsActions from './achievements/achievements.actions';
import { ActionReducerMap, MetaReducer } from '@ngrx/store';
import { AppState } from './app.state';
import { seasonReducer } from './season/season.reducer';
import { playersReducer } from './players/players.reducers';
import { matchesReducer } from './matches/matches.reducers';
import { errorReducer } from './error/error.reducer';
import { statisticsReducer } from './statistics/statistics.reducer';
import { sidebarReducer } from './sidebar/sidebar.reducer';
import { usersReducer } from './user/user.reducer';
import { scoreboardReducer } from './scoreboards/scoreboards.reducer';
import { environment } from '../../environments/environment';
import { achievementsReducer } from './achievements/achievements.reducer';
import { SeasonEffects } from './season/season.effects';
import { PlayersEffects } from './players/players.effects';
import { MatchesEffects } from './matches/matches.effects';
import { StatisticsEffects } from './statistics/statistics.effects';
import { SidebarEffects } from './sidebar/sidebar.effects';
import { ErrorEffects } from './error/error.effects';
import { UserEffects } from './user/user.effects';
import { ScoreboardsEffects } from './scoreboards/scoreboards.effects';
import { AchievementsEffects } from './achievements/achievements.effects';

export {
    matchesActions,
    errorActions,
    playersActions,
    seasonActions,
    sidebarActions,
    statisticsActions,
    userActions,
    scoreboardsActions,
    scoreboardsSelectors,
    achievementsActions,
    playersSelectors,
};
export { AppState } from './app.state';

export const reducers: ActionReducerMap<AppState> = {
    season: seasonReducer,
    players: playersReducer,
    matches: matchesReducer,
    error: errorReducer,
    statistics: statisticsReducer,
    sidebar: sidebarReducer,
    user: usersReducer,
    scoreboards: scoreboardReducer,
    achievements: achievementsReducer,
};

export const effects = [
    SeasonEffects,
    PlayersEffects,
    MatchesEffects,
    StatisticsEffects,
    SidebarEffects,
    ErrorEffects,
    UserEffects,
    ScoreboardsEffects,
    AchievementsEffects,
];

export const metaReducers: Array<MetaReducer<AppState>> = !environment.production ? [] : [];
