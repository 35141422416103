<div class="btn-group" role="group" aria-label="Small button group">
    @for (item of items; track $index) {
        <button
            type="button"
            class="btn"
            [ngClass]="{ 'btn-success': item === selectedItem, 'btn-outline-success': item !== selectedItem }"
            (click)="selectItem(item)"
        >
            {{ item.title }}
        </button>
    }
</div>
<div class="mt-1">{{ selectedItem?.text }}</div>
