import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
    // eslint-disable-next-line @angular-eslint/directive-selector
    selector: '[fitText]',
})
export class FitTextDirective {
    #compressor = 1;
    @Input() maxFontSize = Infinity;

    @Input() minFontSize = -Infinity;

    @HostListener('window:resize')
    onResize() {
        this.resizer();
    }

    @HostListener('window:orientationchange')
    onOrientationChange() {
        this.resizer();
    }

    @Input()
    set fitText(value: number) {
        this.#compressor = value;
        this.resizer();
    }

    constructor(private readonly elementRef: ElementRef<HTMLElement>) {
        setTimeout(() => {
            this.resizer();
        });
    }

    private resizer() {
        const el = this.elementRef.nativeElement;
        el.style.fontSize = Math.max(Math.min(el.clientWidth / (this.#compressor * 10), this.maxFontSize), this.minFontSize) + 'px';
    }
}
