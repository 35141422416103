import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store, select } from '@ngrx/store';
import { Session } from 'core/session.service';
import { Player, PlayerStatisticsCollectionModel, PlayerStatisticsModel, TeamSettings } from 'models';
import { forkJoin, of, throwError } from 'rxjs';
import { catchError, filter, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { AppState } from 'store';
import {
    createNamedTeam,
    deleteNamedTeam,
    loadNamedTeams,
    loadNamedTeamsSuccess,
    loadPlayerLevel,
    loadPlayerLevelSuccess,
    loadPlayers,
    loadPlayersStats,
    loadPlayersStatsDetail,
    loadPlayersStatsDetailSuccess,
    loadPlayersStatsSuccess,
    loadPlayersSuccess,
    loadSettings,
    loadSettingsSuccess,
    removeFromTeam,
    updatePlayer,
    updateSettings,
} from './players.actions';
import { IPlayer, IPlayerLevel, NamedPlayersTeamExt } from './players.model';

@Injectable()
export class PlayersEffects {
    loadPlayers$ = createEffect(() =>
        this.actions$.pipe(
            ofType(loadPlayers),
            withLatestFrom(this.store$.pipe(select((s) => s.players.all))),
            filter(([action, state]) => !state.length || action.force),
            switchMap(() =>
                this.http.get<{ values: Array<IPlayer> }>('/api/players').pipe(
                    map((res) =>
                        loadPlayersSuccess({
                            players: res.values,
                        }),
                    ),
                ),
            ),
        ),
    );

    loadNamedTeams$ = createEffect(() =>
        this.actions$.pipe(
            ofType(loadNamedTeams),
            withLatestFrom(this.store$.pipe(select((s) => s.players.namedTeams))),
            filter(([action, state]) => !state.length || action.force),
            switchMap(() =>
                this.http.get<Array<NamedPlayersTeamExt>>('/api/named-players-teams').pipe(
                    map((res) =>
                        loadNamedTeamsSuccess({
                            namedTeams: res,
                        }),
                    ),
                ),
            ),
        ),
    );

    createNamedTeam$ = createEffect(() =>
        this.actions$.pipe(
            ofType(createNamedTeam),
            switchMap((action) =>
                this.http.post(`/api/named-players-teams`, action.namedTeam).pipe(map(() => loadNamedTeams({ force: true }))),
            ),
        ),
    );
    deleteNamedTeam$ = createEffect(() =>
        this.actions$.pipe(
            ofType(deleteNamedTeam),
            switchMap((action) =>
                this.http.delete(`/api/named-players-teams/${action.namedTeam.id}`).pipe(map(() => loadNamedTeams({ force: true }))),
            ),
        ),
    );

    loadPlayersStats$ = createEffect(() =>
        this.actions$.pipe(
            ofType(loadPlayersStats),
            switchMap((action) => {
                let p = new HttpParams();
                if (action.season.id) {
                    p = p.set('season', action.season.id.toString());
                }
                return this.http
                    .get<Array<PlayerStatisticsCollectionModel>>('/api/stats/players', { params: p })
                    .pipe(map((res) => loadPlayersStatsSuccess({ stats: res })));
            }),
        ),
    );

    loadPlayersStatsDetail$ = createEffect(() =>
        this.actions$.pipe(
            ofType(loadPlayersStatsDetail),
            switchMap((action) => {
                let p = new HttpParams();
                if (action.season.id) {
                    p = p.set('season', action.season.id.toString());
                }
                return forkJoin([
                    this.http.get<PlayerStatisticsModel>(`/api/stats/players/${action.idPlayer}`, { params: p }).pipe(
                        map((res) => {
                            res.winRate.forEach((w) => {
                                w.againstWinPercent = (w.againstWinCount / w.againstMatchCount || 0) * 100;
                                w.withWinPercent = (w.withWinCount / w.withMatchCount || 0) * 100;
                            });
                            return res;
                        }),
                    ),
                ]).pipe(
                    map(([stats]) => {
                        return loadPlayersStatsDetailSuccess({ stats: stats });
                    }),
                );
            }),
        ),
    );

    loadSettings$ = createEffect(() =>
        this.actions$.pipe(
            ofType(loadSettings),
            switchMap(() =>
                this.http.get<TeamSettings>('/api/teams/current/settings').pipe(map((x) => loadSettingsSuccess({ settings: x }))),
            ),
        ),
    );

    updateSettings$ = createEffect(() =>
        this.actions$.pipe(
            ofType(updateSettings),
            switchMap((action) =>
                this.http
                    .patch<TeamSettings>('/api/teams/current/settings', action.settings)
                    .pipe(map((x) => loadSettingsSuccess({ settings: x }))),
            ),
        ),
    );

    updatePlayer$ = createEffect(() =>
        this.actions$.pipe(
            ofType(updatePlayer),
            switchMap((action) => {
                return this.http.patch(`/api/players/${action.player.id}`, action.player).pipe(
                    tap((player: Player) => {
                        this.session.user.gender = player.gender;
                        this.session.user.displayName = player.displayName;
                    }),
                    map(() => loadPlayers({ force: true })),
                );
            }),
        ),
    );

    loadPlayerLevel$ = createEffect(() =>
        this.actions$.pipe(
            ofType(loadPlayerLevel),
            withLatestFrom(this.store$.pipe(select((s) => s.season.active))),
            switchMap(([, season]) => {
                let p = new HttpParams();
                if (season && season.id) {
                    p = p.set('season', season.id.toString());
                }
                return this.http
                    .get<IPlayerLevel>(`/api/players/${this.session.user.idPlayer}/level`, { params: p })
                    .pipe(
                        catchError((err: HttpErrorResponse) => {
                            if (err.status === 404) {
                                return of(null);
                            } else {
                                return throwError(() => err);
                            }
                        }),
                    )
                    .pipe(map((x) => loadPlayerLevelSuccess({ payload: x })));
            }),
        ),
    );

    removeFromTeam$ = createEffect(() =>
        this.actions$.pipe(
            ofType(removeFromTeam),
            switchMap(({ player }) =>
                this.http.delete(`/api/teams/member/${player.id}/remove`).pipe(map(() => loadPlayers({ force: true }))),
            ),
        ),
    );

    constructor(
        private readonly actions$: Actions,
        private store$: Store<AppState>,
        private readonly http: HttpClient,
        private readonly session: Session,
    ) {}
}
