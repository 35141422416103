import { createAction, props } from '@ngrx/store';
import { ApplicationTheme, CurrentUserModel, PlayerLoginsModel } from 'models';

export const setCurrentUser = createAction('[User] Set current user', props<{ user: CurrentUserModel }>());
export const setTheme = createAction('[User] Set theme', props<{ theme: ApplicationTheme }>());
export const setThemeSuccess = createAction('[User] Set theme success', props<{ theme: ApplicationTheme }>());

export const loadLogins = createAction('[PLAYER] Load logins');
export const loadLoginsSuccess = createAction('[PLAYER] Load logins success', props<{ logins: PlayerLoginsModel }>());

export const removeLogin = createAction('[PLAYER] remove login', props<{ provider: string }>());

export const setMenuVisibility = createAction('[PLAYER] set menu visibility', props<{ showMenu: boolean }>());
