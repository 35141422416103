<div class="d-flex flex-column w-100 edit-comment">
    <img *ngIf="imageUrl" [attr.src]="imageUrl" class="comment-img p-3" />
    <textarea
        class="comment-text p-3"
        [placeholder]="placeholder() || ''"
        [ngModel]="comment"
        (ngModelChange)="onTextChange($event)"
        (keydown.enter)="$event.preventDefault(); addComment()"
        rows="1"
    >
    </textarea>

    <div class="controls d-flex flex-row justify-content-between px-3">
        <div class="clickable d-flex flex-row gap-2">
            <mat-icon [matTooltip]="'MATCH.COMMENT_ATTACH_FILE' | translate" [matTooltipShowDelay]="500" *ngIf="supportsAttachments()"
                >attach_file</mat-icon
            >
            <mat-icon (click)="showEmoPicker = !showEmoPicker" [matTooltip]="'MATCH.COMMENT_EMOJI' | translate" [matTooltipShowDelay]="500"
                >insert_emoticon</mat-icon
            >
            <emoji-mart
                [style]="{ position: 'absolute', 'z-index': 1000, bottom: '20px', right: '20px' }"
                *ngIf="showEmoPicker"
                title="Pick your emoji…"
                emoji="point_up"
                [isNative]="true"
                (emojiSelect)="onEmojiSelect($event)"
            ></emoji-mart>
            <mat-icon (click)="addGif()" [matTooltip]="'MATCH.COMMENT_ADD_GIF' | translate" [matTooltipShowDelay]="500">gif_box </mat-icon>
        </div>
        <div class="clickable">
            <mat-icon [class.active]="!!comment" (click)="addComment()" [matTooltip]="'COMMON.SENT' | translate">send</mat-icon>
        </div>
    </div>
</div>
