<h4 mat-dialog-title class="modal-title text-center">
    {{ 'GIPH_MODAL.TITLE' | translate }}
</h4>

<mat-dialog-content>
    <div class="row">
        <div class="col-sm-12">
            <mat-form-field class="search-input">
                <input
                    #searchInput
                    placeholder="{{ 'GIPH_MODAL.SEARCH' | translate }}"
                    matInput
                    [(ngModel)]="searchText"
                    (keyup)="searchTerm$.next(searchInput.value)"
                />
            </mat-form-field>
        </div>
    </div>
    <div class="scrollbar d-flex flex-column justify-content-center" style="overflow-y: auto; height: 60vh">
        <app-loading-panel [loading]="isLoading">
            <div
                class="d-flex d-flex-row flex-wrap clickable"
                infiniteScroll
                [infiniteScrollDistance]="2"
                [scrollWindow]="false"
                [infiniteScrollThrottle]="200"
                (scrolled)="loadNext()"
                style="height: 60vh; overflow-y: auto"
            >
                <img
                    (click)="selectImage(src)"
                    *ngFor="let src of images"
                    [src]="src.previewWebp"
                    [alt]="src.title"
                    class="img-thumbnail"
                />
            </div>
            <div *ngIf="isLoadingNext" class="d-flex justify-content-center align-items-center">
                <mat-progress-bar mode="indeterminate"></mat-progress-bar>
            </div>
        </app-loading-panel>
    </div>
    <div class="pull-right">
        Powered by <a href="https://giphy.com/"><img src="/assets/img/giphy-logo.png" alt="Giphy" /></a>
    </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
    <button type="button" color="primary" mat-dialog-close mat-raised-button>
        {{ 'RELEASE_NOTES.RELOAD_CLOSE_BTN' | translate }}
    </button>
</mat-dialog-actions>
