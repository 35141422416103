import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'trophyPath',
})
export class TrophyPathPipe implements PipeTransform {
    transform(value: string): string {
        return `/assets/img/trophies/${value}.png`;
    }
}
