import { Action, createReducer, createSelector, on } from '@ngrx/store';
import { AppState } from 'store/app.state';
import { apiBadRequestError, apiError, apiForbiddenError, logApplicationException } from './error.actions';
import { IErrorState } from './error.model';

const initialState: IErrorState = {
    applicationExceptions: [],
    lastApiError: null,
    lastBadRequestApiError: null,
    lastForbiddenApiError: null,
};

const reducer = createReducer(
    initialState,
    on(apiError, (state, { error }) => ({
        ...state,
        lastApiError: error,
    })),
    on(apiForbiddenError, (state, { error }) => ({
        ...state,
        lastForbiddenApiError: error,
    })),
    on(apiBadRequestError, (state, payload) => ({
        ...state,
        lastBadRequestApiError: payload.error,
    })),
    on(logApplicationException, (state, { error }) => ({
        ...state,
        applicationExceptions: [
            ...state.applicationExceptions.slice(Math.max(state.applicationExceptions.length - 50, 0)),
            { error: { message: error.message }, reported: false, utcDate: new Date().toISOString() },
        ],
    })),
);

export function errorReducer(state: IErrorState = initialState, action: Action): IErrorState {
    return reducer(state, action);
}

const selectFeature = (state: AppState) => state.error;

export const selectLastApiError = createSelector(selectFeature, (state: IErrorState) => state.lastApiError);
export const selectLastBadRequestApiError = createSelector(selectFeature, (state: IErrorState) => state.lastBadRequestApiError);
export const selectLastForbiddenApiError = createSelector(selectFeature, (state: IErrorState) => state.lastForbiddenApiError);
