import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store, select } from '@ngrx/store';
import { AchievementCollectionItemModel } from 'models';
import { of } from 'rxjs';
import { catchError, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { AppState, achievementsActions } from 'store';
import { selectActiveSeason } from 'store/season/season.reducer';
import { PlayerProgressType } from './achievements.model';

@Injectable()
export class AchievementsEffects {
    $loadAchievementsEffect = createEffect(() =>
        this.actions$.pipe(
            ofType(achievementsActions.loadPlayersAchievements),
            withLatestFrom(this.store$.pipe(select(selectActiveSeason))),
            switchMap(([, season]) =>
                this.http
                    .get<Array<AchievementCollectionItemModel>>('/api/achievements', { params: { idSeason: season?.id || '' } })
                    .pipe(catchError(() => of([]))),
            ),
            map((res) => achievementsActions.loadPlayersAchievementsSuccess({ achievements: res })),
        ),
    );

    $loadPlayerProgressEffect = createEffect(() =>
        this.actions$.pipe(
            ofType(achievementsActions.loadAchievementDetail),
            withLatestFrom(this.store$.pipe(select(selectActiveSeason))),
            switchMap(([{ id }, season]) =>
                this.http.get<PlayerProgressType>(`/api/achievements/${id}`, { params: { idSeason: season?.id || '' } }).pipe(
                    map((value) => ({ value, id })),
                    catchError(() => of(null)),
                ),
            ),
            map((res) => achievementsActions.loadAchievementDetailSuccess(res)),
        ),
    );

    constructor(
        private readonly actions$: Actions,
        private store$: Store<AppState>,
        private readonly http: HttpClient,
    ) {}
}
