import { createReducer, on } from '@ngrx/store';
import { CurrentUserModel, PlayerLoginsModel } from 'models';
import { loadLoginsSuccess, setCurrentUser, setMenuVisibility, setThemeSuccess } from './user.actions';

export const userFeatureKey = 'user';

export interface IUserState {
    current: CurrentUserModel;
    logins: PlayerLoginsModel;
    showMenu: boolean;
}

export const initialState: IUserState = {
    current: null,
    logins: {
        hasPassword: false,
        providers: [],
    },
    showMenu: false,
};

export const usersReducer = createReducer(
    initialState,
    on(setCurrentUser, (state, { user }) => ({ ...state, current: user })),
    on(setThemeSuccess, (state, { theme }) => ({ ...state, current: { ...state.current, theme: theme } })),
    on(loadLoginsSuccess, (state, { logins }) => ({ ...state, logins })),
    on(setMenuVisibility, (state, { showMenu }) => ({ ...state, showMenu: showMenu })),
);
