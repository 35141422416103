import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { bufferTime, catchError, debounceTime, filter, map, mergeMap } from 'rxjs/operators';
import { AppState, errorActions } from 'store';

@Injectable()
export class ErrorEffects {
    $logError = createEffect(
        () =>
            this.actions$.pipe(
                ofType(errorActions.logApplicationException),
                map((x) => x.error),
                debounceTime(200),
                bufferTime(2000),
                filter((x) => x.length > 0),
                mergeMap((action) => this.http.post('/api/logs/error', { errors: action })),
                catchError(() => of(null)),
            ),
        { dispatch: false },
    );

    constructor(
        private readonly actions$: Actions,
        private store$: Store<AppState>,
        private readonly http: HttpClient,
    ) {}
}
