import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store, select } from '@ngrx/store';
import { MainSeasonStatsModel } from 'models';
import { EMPTY } from 'rxjs';
import { exhaustMap, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { AppState } from 'store/app.state';
import { loadChart, loadChartSuccess, loadMainSeasonStats, loadMainSeasonStatsSuccess } from './statistics.actions';
import { IChartItem } from './statistics.model';

@Injectable()
export class StatisticsEffects {
    loadChart$ = createEffect(() =>
        this.actions$.pipe(
            ofType(loadChart),
            withLatestFrom(this.store.pipe(select((x) => x.statistics.chartData))),
            exhaustMap(([action, chartData]) => {
                if (chartData?.length > 0 && chartData[0].idSeason === action.idSeason) {
                    return EMPTY;
                }
                let p = new HttpParams();
                if (action.idSeason) {
                    p = p.set('season', action.idSeason.toString());
                }
                return this.http
                    .get<{ values: Array<IChartItem> }>('/api/charts', { params: p })
                    .pipe(map((x) => loadChartSuccess({ payload: x.values })));
            }),
        ),
    );
    loadMainSeasonStatsChart$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(loadMainSeasonStats),
            withLatestFrom(this.store.pipe(select((x) => x.season.active))),
            switchMap(([, season]) => {
                let params = new HttpParams();
                if (season?.id != null) {
                    params = params.set('season', String(season.id));
                }
                return this.http.get<MainSeasonStatsModel>('/api/stats/seasons/main', { params });
            }),
            map((x) => loadMainSeasonStatsSuccess(x)),
        );
    });

    constructor(
        private readonly actions$: Actions,
        private readonly http: HttpClient,
        private readonly store: Store<AppState>,
    ) {}
}
