import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { map, switchMap, withLatestFrom } from 'rxjs/operators';

import { HttpClient, HttpParams } from '@angular/common/http';
import { Store, select } from '@ngrx/store';
import {
    EloScoreboardModelArrayResult,
    MainScoreboardItemModelArrayResult,
    OffenseDefenseScoreboardModelArrayResult,
    TeamScoreboardItemModelArrayResult,
} from 'models';
import { AppState } from 'store';
import * as scoreboardsActions from './scoreboards.actions';

@Injectable()
export class ScoreboardsEffects {
    loadMainScoreboards$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(scoreboardsActions.loadMain),
            withLatestFrom(this.store.pipe(select((x) => x.season.active))),
            switchMap(([, season]) => {
                let params = new HttpParams();
                if (season?.id != null) {
                    params = params.set('season', String(season.id));
                }
                return this.http.get<MainScoreboardItemModelArrayResult>('/api/scoreboards/main', { params });
            }),
            map((x) => scoreboardsActions.loadMainSuccess({ scoreboard: x.values })),
        );
    });
    loadTeamScoreboards$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(scoreboardsActions.loadTeams),
            withLatestFrom(this.store.pipe(select((x) => x.season.active))),
            switchMap(([, season]) => {
                let params = new HttpParams();
                if (season?.id != null) {
                    params = params.set('season', String(season.id));
                }
                return this.http.get<TeamScoreboardItemModelArrayResult>('/api/scoreboards/teams', { params });
            }),
            map((x) => scoreboardsActions.loadTeamsSuccess({ scoreboard: x.values })),
        );
    });
    loadDefenseGivenScoreboards$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(scoreboardsActions.loadDefenseGiven),
            withLatestFrom(this.store.pipe(select((x) => x.season.active))),
            switchMap(([, season]) => {
                let params = new HttpParams();
                if (season?.id != null) {
                    params = params.set('season', String(season.id));
                }
                return this.http.get<OffenseDefenseScoreboardModelArrayResult>('/api/scoreboards/defense-given', { params });
            }),
            map((x) => scoreboardsActions.loadDefenseGivenSuccess({ scoreboard: x.values })),
        );
    });
    loadDefenseReceivedScoreboards$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(scoreboardsActions.loadDefenseReceived),
            withLatestFrom(this.store.pipe(select((x) => x.season.active))),
            switchMap(([, season]) => {
                let params = new HttpParams();
                if (season?.id != null) {
                    params = params.set('season', String(season.id));
                }
                return this.http.get<OffenseDefenseScoreboardModelArrayResult>('/api/scoreboards/defense-received', { params });
            }),
            map((x) => scoreboardsActions.loadDefenseReceivedSuccess({ scoreboard: x.values })),
        );
    });
    loadOffenseScoreboards$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(scoreboardsActions.loadOffense),
            withLatestFrom(this.store.pipe(select((x) => x.season.active))),
            switchMap(([, season]) => {
                let params = new HttpParams();
                if (season?.id != null) {
                    params = params.set('season', String(season.id));
                }
                return this.http.get<OffenseDefenseScoreboardModelArrayResult>('/api/scoreboards/offense', { params });
            }),
            map((x) => scoreboardsActions.loadOffenseSuccess({ scoreboard: x.values })),
        );
    });
    loadEloScoreboards$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(scoreboardsActions.loadElo),
            withLatestFrom(this.store.pipe(select((x) => x.season.active))),
            switchMap(([, season]) => {
                let params = new HttpParams();
                if (season?.id != null) {
                    params = params.set('season', String(season.id));
                }
                return this.http.get<EloScoreboardModelArrayResult>('/api/scoreboards/elo', { params });
            }),
            map((x) => scoreboardsActions.loadEloSuccess({ scoreboard: x.values })),
        );
    });

    constructor(
        private actions$: Actions,
        private readonly http: HttpClient,
        private store: Store<AppState>,
    ) {}
}
