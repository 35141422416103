<button class="season-select" mat-button [matMenuTriggerFor]="menu" *ngIf="activeSeason">
    <span *ngIf="activeSeason.id">{{ activeSeason.name }}</span>
    <span *ngIf="!activeSeason.id">{{ 'SEASONS.ALL' | translate }}</span>
</button>
<mat-menu #menu="matMenu">
    <button (click)="selectSeason(s)" mat-menu-item *ngFor="let s of main">
        <span *ngIf="s.id">{{ s.name }}</span>
        <span *ngIf="!s.id">{{ 'SEASONS.ALL' | translate }}</span>
    </button>
    <button *ngIf="older.length" mat-menu-item [matMenuTriggerFor]="others">{{ 'SEASONS.OLDER' | translate }}</button>
</mat-menu>

<mat-menu #others="matMenu">
    <button (click)="selectSeason(s)" mat-menu-item *ngFor="let s of older">
        {{ s.name }}
    </button>
</mat-menu>
