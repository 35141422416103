import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'sortDesc',
})
export class SortDescPipe implements PipeTransform {
    transform(value: any, ...args: Array<any>): any {
        if (!Array.isArray(value)) {
            return value;
        }
        if (!args[0]) {
            return value.sort();
        } else {
            const property = args[0];
            return value.sort((a, b) => (a[property] < b[property] ? 1 : a[property] > b[property] ? -1 : 0));
        }
    }
}
