import { createAction, props } from '@ngrx/store';
import { AchievementCollectionItemModel } from 'models';
import { PlayerProgressType } from './achievements.model';

export const loadPlayersAchievements = createAction('[ACHIEVEMENTS] Load');
export const loadPlayersAchievementsSuccess = createAction(
    '[ACHIEVEMENTS] Load success',
    props<{ achievements: Array<AchievementCollectionItemModel> }>(),
);

export const loadAchievementDetail = createAction('[ACHIEVEMENTS] Load detail', props<{ id: number }>());
export const loadAchievementDetailSuccess = createAction(
    '[ACHIEVEMENTS] Load detail success',
    props<{ id: number; value: PlayerProgressType }>(),
);

export const clearSelectedPlayer = createAction('[ACHIEVEMENTS] Clear selected player');
