import { HttpClient } from '@angular/common/http';
import { Component, DestroyRef, OnInit, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Store, select } from '@ngrx/store';
import { Session } from 'core/session.service';
import { BasicTeamModel } from 'models';
import { AppState } from 'store';

@Component({
    selector: 'app-switch-team-modal',
    templateUrl: './switch-team-modal.component.html',
    styleUrls: ['./switch-team-modal.component.scss'],
})
export class SwitchTeamModalComponent implements OnInit {
    #destroyRef = inject(DestroyRef);

    public currentTeam = 0;
    public teams: Array<BasicTeamModel>;
    public isSwitching = false;

    constructor(
        private readonly session: Session,
        private readonly store: Store<AppState>,
        private readonly http: HttpClient,
    ) {
        this.currentTeam = session.user.idTeam;
    }

    ngOnInit(): void {
        this.store
            .pipe(
                select((x) => x.user.current.teams),
                takeUntilDestroyed(this.#destroyRef),
            )
            .subscribe((teams) => (this.teams = teams));
    }

    public selectTeam(team: BasicTeamModel) {
        if (this.currentTeam !== team.id) {
            this.isSwitching = true;
            this.session.switchTeam(team.id);
        }
    }

    public createNewTeam() {
        this.http.delete('/api/teams/clear-selected').subscribe(() => {
            window.location.href = '/account/create/2';
        });
    }
}
