import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SwPush } from '@angular/service-worker';
import { Store } from '@ngrx/store';
import { MatchInvitationPlayerState, PushNotificationProvider, PushNotificationSubscribeModel } from 'models';
import { firstValueFrom } from 'rxjs';
import { AppState, sidebarActions } from 'store';
import uaParser from 'ua-parser-js';
import { BrowserStorageService, storageKeys } from './browser-storage.service';
import { User } from './user';

@Injectable({ providedIn: 'root' })
export class PushNotificationService {
    constructor(
        private readonly http: HttpClient,
        private readonly swPush: SwPush,
        private readonly store: Store<AppState>,
        private readonly storage: BrowserStorageService,
    ) {}

    public subscribeToNotificationActions() {
        this.swPush.notificationClicks.subscribe((event) => {
            const { id, idPlayer } = event.notification.data.payload as Record<string, string>;
            switch (event.action) {
                case 'invite-match-accept':
                    this.onMatchInviteClick(id, idPlayer, MatchInvitationPlayerState.Accepted);
                    break;
                case 'invite-match-refuse':
                    this.onMatchInviteClick(id, idPlayer, MatchInvitationPlayerState.Refused);
                    break;

                default:
                    break;
            }
        });
    }

    public renewPushNotificationSubscription(user: User) {
        if (!this.pushNotificatiosSupported) {
            return;
        }
        return Notification.requestPermission()
            .then((permission) => {
                if (permission === 'granted') {
                    return this.swPush.requestSubscription({ serverPublicKey: user.vapidPublicKey });
                } else {
                    return Promise.reject(new Error(permission));
                }
            })
            .then((subscription) => this.createSubscription(subscription));
    }

    public createSubscription(subscription: PushSubscription) {
        const js = subscription.toJSON() as unknown as PushNotificationSubscribeModel;
        let payload: PushNotificationSubscribeModel = {
            ...js,
            provider: PushNotificationProvider.WebPush,
            device: {
                deviceOs: 'Unknown OS',
                browser: 'Unknown Browser',
                browserVersion: 'Unknown version',
                browserId: this.storage.local.getItem(storageKeys.browserId),
            },
        };
        try {
            const parser = new uaParser();
            const ua = parser.getResult();
            payload = {
                ...payload,
                device: {
                    ...payload.device,
                    deviceOs: `${String(ua.os.name)} ${String(ua.os.version)}`,
                    browser: ua.browser.name,
                    browserVersion: ua.browser.version,
                },
            };
        } catch (err) {
            // eslint-disable-next-line no-console
            console.error(err);
        }
        return firstValueFrom(this.http.post<unknown>('/api/push-notifications/subscribe', payload, { params: { force: true } }));
    }

    public get pushNotificatiosSupported(): boolean {
        return this.swPush.isEnabled && 'Notification' in window;
    }

    private onMatchInviteClick(id: string, idPlayer: string, state: MatchInvitationPlayerState) {
        this.store.dispatch(sidebarActions.openSidebar());
        const payload = {
            state,
        };
        this.http.patch(`/api/match-invitations/${id}/players/${idPlayer}`, payload).subscribe();
    }
}
