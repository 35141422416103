import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface InviteModalData {
    idPlayer: number;
}

@Component({
    selector: 'app-invite-modal',
    templateUrl: './invite-modal.component.html',
    styleUrls: ['./invite-modal.component.scss'],
})
export class InviteModalComponent {
    idPlayer: number;

    constructor(@Inject(MAT_DIALOG_DATA) data: InviteModalData) {
        this.idPlayer = data?.idPlayer;
    }
}
