import { createAction, props } from '@ngrx/store';
import { MatchExpectedOutcomeModel, MatchSet, SavedMatchInfoModel, SavedMatchModel, TeamSettings } from 'models';
import { EditingComment, IMatch, IMatchTimers, PlayersPair } from './matches.model';

export const saveActiveMatch = createAction('[MATCHES] Save active match');
export const createActiveMatch = createAction(
    '[MATCHES] Create active',
    props<{ teamA: PlayersPair; teamB: PlayersPair; uniqueId?: string; matchSettings: TeamSettings }>(),
);
export const createActiveMatchSuccess = createAction(
    '[MATCHES] Create active success',
    props<{
        teamA: PlayersPair;
        teamB: PlayersPair;
        uniqueId?: string;
        matchSettings: TeamSettings;
    }>(),
);
export const clearActiveMatch = createAction('[MATCHES] Clear active match', props<{ uniqueId: string }>());
export const clearActiveMatchSuccess = createAction('[MATCHES] Clear active match success', props<{ uniqueId: string }>());
export const restoreActiveMatch = createAction('[MATCHES] Restore active match');
export const restoreActiveMatchSuccess = createAction(
    '[MATCHES] Restore active match success',
    props<{ match: IMatch; timers: IMatchTimers }>(),
);
export const switchTeamRoles = createAction('[MATCHES] Switch team roles', props<{ team: 'A' | 'B' | 'AB' }>());
export const switchTeamMembers = createAction('[MATCHES] Switch team members', props<{ teamA: PlayersPair; teamB: PlayersPair }>());
export const addSetToActiveMatch = createAction('[MATCHES] Add set to active match', props<{ scoreTeamA: number; scoreTeamB: number }>());

export const createActiveMatchSet = createAction('[MATCHES] Create active match set', props<{ set: MatchSet }>());
export const updateActiveMatchSet = createAction('[MATCHES] Update active match set', props<{ set: MatchSet }>());
export const undoLastGoal = createAction('[MATCHES] Undo last goal');
export const setOngoingMatches = createAction('[MATCHES] Set ongoing matches', props<{ matches: Array<IMatch> }>());

export const setStarted = createAction('[MATCHES] Set match started', props<{ started: boolean }>());

export const clearTimers = createAction(
    '[MATCHES] Clear timers',
    props<{ fromMatchStart?: boolean; fromSetStart?: boolean; fromLastGoal?: boolean }>(),
);
export const incrementTimers = createAction('[MATCHES] Increment timers');

export const toggleTimerPause = createAction('[MATCHES] Toggle timers pause');

export const saveOngoingActiveMatch = createAction('[MATCHES] Save ongoing active match', props<{ name: string }>());
export const saveOngoingActiveMatchSuccess = createAction('[MATCHES] Save ongoing active match success');

export const loadSavedMatches = createAction('[MATCHES] Load Saved');
export const loadSavedMatchesSuccess = createAction('[MATCHES] Load Saved success', props<{ values: Array<SavedMatchInfoModel> }>());

export const loadSavedMatchesCount = createAction('[MATCHES] Load Saved count');
export const loadSavedMatchesCountSuccess = createAction('[MATCHES] Load Saved count success', props<{ count: number }>());

export const restoreSavedMatch = createAction('[MATCHES] restore saved', props<{ id: string }>());
export const restoreSavedMatchSuccess = createAction('[MATCHES] restore saved success', props<{ match: SavedMatchModel }>());

export const setExpectedOutcome = createAction('[MATCHES] Set expected outcome', props<{ players: [number, number, number, number] }>());
export const setExpectedOutcomeSuccess = createAction(
    '[MATCHES] Set expected outcome success',
    props<{ expectedOutcome: MatchExpectedOutcomeModel }>(),
);

export const updateMatchComment = createAction(
    '[MATCHES] Update match comment',
    props<{ key: string; comment: EditingComment; skipIfExists: boolean }>(),
);
export const clearMatchComment = createAction('[MATCHES] Clear match comment', props<{ key: string }>());
