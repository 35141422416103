import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromScoreboards from './scoreboards.reducer';

export const selectFeature = createFeatureSelector<fromScoreboards.IScoreboardsState>(fromScoreboards.scoreboardsFeatureKey);

export const main = createSelector(selectFeature, (state) => state.main);
export const teams = createSelector(selectFeature, (state) => state.team);
export const defenseGiven = createSelector(selectFeature, (state) => state.defenseGiven);
export const defenseReceived = createSelector(selectFeature, (state) => state.defenseReceived);
export const offense = createSelector(selectFeature, (state) => state.offense);
export const elo = createSelector(selectFeature, (state) => state.elo);
