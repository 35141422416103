<div class="d-flex flex-column w-100 edit-comment" [class.bordered]="!comment()">
    <img *ngIf="imageUrl" [attr.src]="imageUrl" class="comment-img p-3" />
    <textarea
        class="comment-text p-3"
        #textArea
        [ngModel]="text"
        (ngModelChange)="onTextChange($event)"
        (paste)="onPaste($event)"
        (drop)="onDrop($event)"
        rows="4"
        matInput
        [placeholder]="idParentComment() ? ('MATCH.REPLY_COMMENT_TOOLTIP' | translate) : ('MATCH.COMMENT_TOOLTIP' | translate)"
    >
    </textarea>

    <div class="controls d-flex flex-row justify-content-between px-3">
        <div class="clickable d-flex flex-row gap-2">
            <mat-icon
                (click)="attachmentFileInput.click()"
                class="control-icon clickable"
                [matTooltip]="'MATCH.COMMENT_ATTACH_FILE' | translate"
                [matTooltipShowDelay]="500"
                >attach_file</mat-icon
            >
            <mat-icon (click)="showEmoPicker = !showEmoPicker" [matTooltip]="'MATCH.COMMENT_EMOJI' | translate" [matTooltipShowDelay]="500"
                >insert_emoticon</mat-icon
            >
            <emoji-mart
                [style]="{ position: 'absolute', 'z-index': 1000 }"
                *ngIf="showEmoPicker"
                title="Pick your emoji…"
                emoji="point_up"
                [isNative]="true"
                (emojiSelect)="onEmojiSelect($event)"
            ></emoji-mart>

            <input
                type="file"
                title="attachment"
                (change)="onAttachmentFileChange($event)"
                class="d-none"
                #attachmentFileInput
                accept="video/*,image/*"
                multiple="true"
            />
            <emoji-mart
                [style]="{ position: 'absolute', 'z-index': 1000, bottom: '20px', right: '20px' }"
                *ngIf="showEmoPicker"
                title="Pick your emoji…"
                emoji="point_up"
                [isNative]="true"
                (emojiSelect)="onEmojiSelect($event)"
            ></emoji-mart>
            <mat-icon (click)="addGif()" [matTooltip]="'MATCH.COMMENT_ADD_GIF' | translate" [matTooltipShowDelay]="500">gif_box </mat-icon>
        </div>
        <div class="clickable">
            <mat-icon [class.active]="!!comment" (click)="sentComment()" [matTooltip]="'COMMON.SENT' | translate">send</mat-icon>
        </div>
    </div>
</div>

<div class="d-flex flex-column">
    <div class="row" *ngIf="attachments.length">
        <app-comment-attachment
            *ngFor="let attachment of attachments"
            [attachment]="attachment"
            [class.col-lg-6]="attachment.contentType.startsWith('image')"
            [class.col-lg-12]="attachment.contentType.startsWith('video')"
        >
        </app-comment-attachment>
    </div>
    <div class="d-flex flex-row flex-wrap gap-2 mb-4" *ngIf="uploadingAttachments.length">
        <app-uploading-comment-attachment
            *ngFor="let attachment of uploadingAttachments"
            [attachment]="attachment"
        ></app-uploading-comment-attachment>
    </div>
</div>
