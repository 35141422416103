import { Pipe, PipeTransform } from '@angular/core';
import { environment } from '../../../environments/environment';

@Pipe({
    name: 'avatarUrl',
})
export class AvatarUrlPipe implements PipeTransform {
    transform(value: number): string | number {
        return `/api/${environment.apiVersion}/players/${value}/avatar`;
    }
}
