import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { select, Store } from '@ngrx/store';
import { SavedMatchInfoModel } from 'models';
import { takeUntil, Subject } from 'rxjs';
import { AppState, matchesActions } from 'store';
import { selectSaved } from 'store/matches/matches.reducers';

@Component({
    selector: 'app-load-saved-match',
    templateUrl: './load-saved-match.component.html',
    styleUrls: ['./load-saved-match.component.scss'],
})
export class LoadSavedMatchComponent implements OnInit, OnDestroy {
    public savedMatches: Array<SavedMatchInfoModel>;
    #unsubscribe = new Subject<void>();

    constructor(
        private readonly dialogRef: MatDialogRef<LoadSavedMatchComponent>,
        private readonly store: Store<AppState>,
    ) {}

    ngOnInit(): void {
        this.store.pipe(select(selectSaved), takeUntil(this.#unsubscribe)).subscribe((x) => (this.savedMatches = x));
        this.store.dispatch(matchesActions.loadSavedMatches());
    }

    ngOnDestroy(): void {
        this.#unsubscribe.next();
        this.#unsubscribe.complete();
    }

    public loadMatch(value: SavedMatchInfoModel) {
        this.store.dispatch(matchesActions.restoreSavedMatch({ id: value.id }));
        this.dialogRef.close();
    }
}
