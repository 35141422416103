export interface IMedal {
    code: string;
    name: string;
    width: number;
}

export const medals: Array<IMedal> = [
    {
        code: 'gold',
        name: 'MEDAL.GOLD',
        width: 26,
    },
    {
        code: 'silver',
        name: 'MEDAL.SILVER',
        width: 22,
    },
    {
        code: 'bronze',
        name: 'MEDAL.BRONZE',
        width: 20,
    },
    {
        code: 'potato',
        name: 'MEDAL.POTATO',
        width: 28,
    },
    {
        code: 'acorn',
        name: 'MEDAL.ACORN',
        width: 30,
    },
    {
        code: 'butter',
        name: 'MEDAL.BUTTER',
        width: 55,
    },
    {
        code: 'cake',
        name: 'MEDAL.CAKE',
        width: 40,
    },
    {
        code: 'calf',
        name: 'MEDAL.CALF',
        width: 40,
    },
    {
        code: 'champion',
        name: 'MEDAL.CHAMPION',
        width: 20,
    },
    {
        code: 'donkey',
        name: 'MEDAL.DONKEY',
        width: 30,
    },
    {
        code: 'ear',
        name: 'MEDAL.EAR',
        width: 20,
    },
    {
        code: 'hoof',
        name: 'MEDAL.HOOF',
        width: 30,
    },
    {
        code: 'lama',
        name: 'MEDAL.LAMA',
        width: 30,
    },
    {
        code: 'log',
        name: 'MEDAL.LOG',
        width: 30,
    },
    {
        code: 'oven',
        name: 'MEDAL.OVEN',
        width: 25,
    },
    {
        code: 'salad',
        name: 'MEDAL.SALAD',
        width: 38,
    },
    {
        code: 'sausage',
        name: 'MEDAL.SAUSAGE',
        width: 40,
    },
    {
        code: 'shovel',
        name: 'MEDAL.SHOVEL',
        width: 30,
    },
    {
        code: 'coconut',
        name: 'MEDAL.COCONUT',
        width: 30,
    },
    {
        code: 'tail',
        name: 'MEDAL.TAIL',
        width: 20,
    },
    {
        code: 'deer',
        name: 'MEDAL.DEER',
        width: 48,
    },
    {
        code: 'pipe',
        name: 'MEDAL.PIPE',
        width: 30,
    },
];
