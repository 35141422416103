import { Component, input } from '@angular/core';
import { MatchInvitationCommentModel } from 'models';

@Component({
    selector: 'app-match-invitation-comment',
    templateUrl: './match-invitation-comment.component.html',
    styleUrl: './match-invitation-comment.component.scss',
})
export class MatchInvitationCommentComponent {
    public comment = input.required<MatchInvitationCommentModel>();
}
