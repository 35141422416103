import { HttpClient, HttpParams } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap, tap } from 'rxjs/operators';

export interface IGiph {
    previewWebp: string;
    fixedHeight: string;
    still480w: string;
    title: string;
}

@Component({
    selector: 'app-giph-modal',
    templateUrl: './giph-modal.component.html',
    styleUrls: ['./giph-modal.component.scss'],
})
export class GiphModalComponent implements OnInit {
    public images: Array<IGiph> = [];
    public searchTerm$ = new Subject<string>();
    public searchText = '';

    public isLoading = false;
    public isLoadingNext = false;

    constructor(
        private readonly dialogRef: MatDialogRef<IGiph>,
        private readonly http: HttpClient,
    ) {}

    ngOnInit() {
        this.searchTerm$
            .pipe(
                debounceTime(400),
                distinctUntilChanged(),
                tap(() => (this.isLoading = true)),
                switchMap((term) => {
                    this.images = [];
                    if (term) {
                        return this.http.get<any>('/api/giphy/search', { params: { q: term, offset: '0' } });
                    } else {
                        return this.http.get<any>('/api/giphy/trending', { params: { offset: '0' } });
                    }
                }),
            )
            .subscribe((res) => {
                this.images.push(...res.values);
                this.isLoading = false;
            });

        this.searchTerm$.next(null);
    }

    public loadNext() {
        this.isLoadingNext = true;
        let url = '/api/giphy/trending';
        let params = new HttpParams().set('offset', (this.images.length + 1).toString());
        if (this.searchText) {
            url = '/api/giphy/search';
            params = params.set('q', this.searchText);
        }
        this.http.get<any>(url, { params }).subscribe({
            next: (res) => {
                this.images = [...this.images, ...res.values];
            },
            complete: () => (this.isLoadingNext = false),
        });
    }

    public selectImage(giph: IGiph) {
        this.dialogRef.close(giph);
    }
}
