import { IStatisticsState } from './statistics.model';
import { Action, createReducer, on } from '@ngrx/store';
import { loadChartSuccess, loadMainSeasonStatsSuccess } from './statistics.actions';

const initialState: IStatisticsState = {
    chartData: [],
    seasonStats: null,
};

const reducer = createReducer(
    initialState,
    on(loadChartSuccess, (state, { payload }) => ({ ...state, chartData: payload })),
    on(loadMainSeasonStatsSuccess, (state, stats) => ({ ...state, seasonStats: stats })),
);

export function statisticsReducer(state: IStatisticsState = initialState, action: Action): IStatisticsState {
    return reducer(state, action);
}
