<div
    class="elo"
    [matTooltip]="
        elo2 ? ('MATCHBOX.PREV_ELO' | translate: { elo1: elo1.eloPoints, elo2: elo2.eloPoints, sum: elo1.eloPoints + elo2.eloPoints }) : ''
    "
>
    <div class="text-center outcome">{{ expectedOutcome * 100 | number: '1.0-0' }} %</div>
    <div>
        <ng-container *ngTemplateOutlet="icon; context: { elo: elo1 }"></ng-container>
        <ng-container *ngTemplateOutlet="value; context: { elo: elo1 }"></ng-container>
    </div>
    <div *ngIf="elo2">
        <ng-container *ngTemplateOutlet="icon; context: { elo: elo2 }"></ng-container>
        <ng-container *ngTemplateOutlet="value; context: { elo: elo2 }"></ng-container>
    </div>
</div>

<ng-template #icon let-elo="elo">
    <i *ngIf="elo.eloPointsDelta >= 10" class="icon up fas fa-angle-double-up"></i>
    <i *ngIf="elo.eloPointsDelta <= -10" class="icon down fas fa-angle-double-down"></i>
    <i *ngIf="elo.eloPointsDelta < 10 && elo.eloPointsDelta > 0" class="icon up fas fa-angle-up"></i>
    <i *ngIf="elo.eloPointsDelta > -10 && elo.eloPointsDelta < 0" class="icon down fas fa-angle-down"></i>
</ng-template>

<ng-template #value let-elo="elo">
    <span *ngIf="elo.eloPointsDelta > 0">+</span><span [class.elo-no-icon]="elo.eloPointsDelta === 0">{{ elo.eloPointsDelta }} ELO</span>
</ng-template>
