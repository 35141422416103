import { createReducer, on } from '@ngrx/store';
import { EloScoreboardModel, MainScoreboardItemModel, OffenseDefenseScoreboardModel, TeamScoreboardItemModel } from 'models';
import * as scoreboardsActions from './scoreboards.actions';

export const scoreboardsFeatureKey = 'scoreboards';

export interface IScoreboardsState {
    main: Array<MainScoreboardItemModel>;
    team: Array<TeamScoreboardItemModel>;
    offense: Array<OffenseDefenseScoreboardModel>;
    defenseGiven: Array<OffenseDefenseScoreboardModel>;
    defenseReceived: Array<OffenseDefenseScoreboardModel>;
    elo: Array<EloScoreboardModel>;
}

export const initialState: IScoreboardsState = {
    main: null,
    team: null,
    defenseGiven: [],
    defenseReceived: [],
    offense: [],
    elo: [],
};

export const scoreboardReducer = createReducer(
    initialState,
    on(scoreboardsActions.loadMainSuccess, (state, { scoreboard }) => ({ ...state, main: scoreboard })),
    on(scoreboardsActions.loadTeamsSuccess, (state, { scoreboard }) => ({ ...state, team: scoreboard })),
    on(scoreboardsActions.loadDefenseGivenSuccess, (state, { scoreboard }) => ({ ...state, defenseGiven: scoreboard })),
    on(scoreboardsActions.loadDefenseReceivedSuccess, (state, { scoreboard }) => ({ ...state, defenseReceived: scoreboard })),
    on(scoreboardsActions.loadOffenseSuccess, (state, { scoreboard }) => ({ ...state, offense: scoreboard })),
    on(scoreboardsActions.loadEloSuccess, (state, { scoreboard }) => ({ ...state, elo: scoreboard })),
);
