<mat-card appearance="outlined" class="mt-3">
    <mat-card-header>
        <mat-card-title>{{ 'INVITE.INVITE_EMAIL_TITLE' | translate }}</mat-card-title>
        <mat-card-subtitle>{{ 'INVITE.INVITE_EMAIL_SUBTITLE' | translate }}</mat-card-subtitle>
    </mat-card-header>

    <mat-card-content>
        <form class="mb-0 flex-grow-1" (submit)="sendInviteEmail(emailSentSwal, form)" #form="ngForm" action="javascript:void(0)">
            <div class="d-flex flex-wrap justify-content-between">
                <mat-form-field class="mb-0 flex-grow-1">
                    <mat-label>{{ 'REGISTER.EMAIL' | translate }}</mat-label>
                    <input name="name" matInput [formControl]="email" />
                    <mat-error *ngIf="email.touched && email.hasError('required')">{{ 'COMMON.VALIDATOR_REQUIRED' | translate }}</mat-error>
                    <mat-error *ngIf="email.touched && email.hasError('email')">{{ 'COMMON.VALIDATOR_EMAIL' | translate }}</mat-error>
                    <mat-icon
                        [matTooltip]="'REGISTER.EMAIL_SEND_TOOLTIP' | translate"
                        class="clickable"
                        (click)="sendInviteEmail(emailSentSwal, form)"
                        matSuffix
                        >send</mat-icon
                    >
                </mat-form-field>

                <swal
                    [position]="'top-end'"
                    [timer]="1500"
                    [title]="'INVITE.INVITE_EMAIL_SENT_TITLE' | translate"
                    [text]="'INVITE.INVITE_EMAIL_SENT_TEXT' | translate: { email: email.value }"
                    [icon]="'success'"
                    [showConfirmButton]="false"
                    #emailSentSwal
                ></swal>
            </div>
        </form>
    </mat-card-content>
</mat-card>

<div class="separator">
    <span class="text">{{ 'INVITE.OR' | translate }}</span>
</div>

<mat-card appearance="outlined">
    <mat-card-header>
        <mat-card-title>{{ 'INVITE.SHARE_LINK_TITLE' | translate }}</mat-card-title>
    </mat-card-header>

    <mat-card-content>
        <mat-card-subtitle>{{ 'INVITE.SHARE_LINK_SUBTITLE' | translate }}</mat-card-subtitle>

        <mat-form-field class="w-100">
            <input (click)="inviteLinkInput.select()" matInput #inviteLinkInput type="text" [value]="inviteLink$ | async" />

            <mat-icon
                class="clickable"
                [matTooltip]="'REGISTER.COPY_LINK_TOOLTIP' | translate"
                (click)="copyInviteLink(inviteLinkInput)"
                matSuffix
                >file_copy</mat-icon
            >
        </mat-form-field>
    </mat-card-content>
</mat-card>

<div class="separator" *ngIf="!idPlayer">
    <span class="text">{{ 'INVITE.OR' | translate }}</span>
</div>

<mat-card appearance="outlined" *ngIf="!idPlayer">
    <mat-card-header>
        <mat-card-title>{{ 'INVITE.CREATE_REGISTRATION_TITLE' | translate }}</mat-card-title>
        <mat-card-subtitle>{{ 'INVITE.CREATE_REGISTRATION_SUBTITLE' | translate }}</mat-card-subtitle>
    </mat-card-header>

    <mat-card-content>
        <swal
            [position]="'top-end'"
            [timer]="1500"
            [title]="'INVITE.UNREGISTERED_PLAYER_CREATED_TITLE' | translate"
            [icon]="'success'"
            [showConfirmButton]="false"
            #unregisteredPlayerSwal
        ></swal>
        <form (submit)="createUnregistered(unregisteredPlayerSwal, form)" #form="ngForm">
            <div class="d-flex flex-column">
                <mat-form-field class="w-100">
                    <mat-label>{{ 'REGISTER.FIRST_NAME' | translate }}</mat-label>
                    <input name="name" #displayNameInput matInput required [formControl]="unregisteredPlayer.get('displayName')" />
                    <mat-error
                        *ngIf="unregisteredPlayer.get('displayName').touched && unregisteredPlayer.get('displayName').hasError('required')"
                        >{{ 'COMMON.VALIDATOR_REQUIRED' | translate }}</mat-error
                    >
                    <mat-icon matSuffix>person</mat-icon>
                </mat-form-field>
                <button class="align-self-center" type="submit" color="primary" mat-raised-button>
                    {{ 'INVITE.CREATE_REGISTRATION' | translate }}
                </button>
            </div>
        </form>
    </mat-card-content>
</mat-card>
