import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Store, select } from '@ngrx/store';
import { MainScoreboardItemModel, TeamSettings } from 'models';
import { Subject } from 'rxjs';
import { medals } from 'shared/medal-img/medals';
import {
    ScoreboardHistoryModalComponent,
    ScoreboardHistoryModalCotext,
} from 'shared/scoreboard-history-modal/scoreboard-history-modal.component';
import { AppState, playersSelectors } from 'store';

@Component({
    selector: 'app-main-scoreboard',
    templateUrl: './main-scoreboard.component.html',
    styleUrls: ['../scoreboard.component.scss'],
})
export class MainScoreboardComponent implements OnInit, OnDestroy {
    #unsubscribe = new Subject<void>();
    #items: Array<MainScoreboardItemModel> = [];
    notQualified: Array<MainScoreboardItemModel> = [];

    public get items() {
        return this.#items;
    }

    @Input() set items(value: Array<MainScoreboardItemModel>) {
        if (!value?.length) {
            this.#items = [];
            this.notQualified = [];
            return;
        }
        this.#items = value.filter((x) => x.qualifies);
        this.notQualified = value.filter((x) => !x.qualifies);
    }
    @Input() height: number;
    settings: TeamSettings;

    constructor(
        private readonly store: Store<AppState>,
        private readonly dialog: MatDialog,
    ) {}

    ngOnInit(): void {
        this.store.pipe(select(playersSelectors.selectSettings)).subscribe((settings) => (this.settings = settings));
    }

    ngOnDestroy(): void {
        this.#unsubscribe.next();
        this.#unsubscribe.complete();
    }

    public getMedal(position: number, settings: TeamSettings) {
        switch (position) {
            case 1:
                return medals.find((x) => x.code === 'gold');
            case 2:
                return medals.find((x) => x.code === 'silver');
            case 3:
                return medals.find((x) => x.code === 'bronze');
            case 4:
                return medals.find((x) => x.code === 'potato');
            default:
                if (settings.showAditionalPositionImages && settings.additionalMedalsSettings) {
                    if (position > 4 && position === this.items?.length) {
                        return medals.find((x) => x.code === 'champion');
                    }
                    const medalCode = settings.additionalMedalsSettings[position - 5];
                    if (medalCode) {
                        return medals.find((x) => x.code === medalCode) || null;
                    }
                }
                break;
        }

        return null;
    }

    public openHistory() {
        this.dialog.open<ScoreboardHistoryModalComponent, ScoreboardHistoryModalCotext>(ScoreboardHistoryModalComponent, {
            data: { type: 'main', notQualified: this.notQualified.map((x) => x.id) },
            panelClass: 'dialog-xl',
            height: '90vh',
        });
    }
}
