import { createAction, props } from '@ngrx/store';
import { Season } from 'models';

export const changeSeason = createAction('[SEASON] Change', props<{ payload: Season }>());
export const loadSeasons = createAction('[SEASON] Load');
export const loadSeasonsSuccess = createAction('[SEASON] Load success', props<{ seasons: Array<Season>; current: Season }>());
export const toggleSeasonSelect = createAction('[SEASON] Toggle select', props<{ allowSelect: boolean }>());
export const deleteSeason = createAction('[SEASON] Delete', props<{ season: Season }>());
export const addSeason = createAction('[SEASON] Add', props<{ season: Season }>());
export const updateSeason = createAction('[SEASON] Update', props<{ season: Season }>());
