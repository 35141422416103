import { Action, createReducer, createSelector, on } from '@ngrx/store';
import { achievementsActions } from 'store';
import { AppState } from 'store/app.state';
import { IAchievementsState } from './achievements.model';

const initialState: IAchievementsState = {
    playersAchievements: [],
    selectedPlayer: null,
};

const reducer = createReducer(
    initialState,
    on(achievementsActions.loadPlayersAchievementsSuccess, (state, { achievements }) => ({
        ...state,
        playersAchievements: achievements,
    })),
    on(achievementsActions.loadAchievementDetailSuccess, (state, { id, value }) => ({ ...state, selectedPlayer: { id, data: value } })),
    on(achievementsActions.clearSelectedPlayer, (state) => ({ ...state, selectedPlayer: null })),
);

export function achievementsReducer(state: IAchievementsState = initialState, action: Action): IAchievementsState {
    return reducer(state, action);
}

const selectFeature = (state: AppState) => state.achievements;
export const selectPlayersAchievements = createSelector(selectFeature, (state) => state.playersAchievements);
export const selectSelectedPlayer = createSelector(selectFeature, (state) => state.selectedPlayer);
