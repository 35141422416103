import { Component, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

export interface CardOptionItem {
    value: string;
    title: string;
    text: string;
}

@Component({
    selector: 'app-card-options-select',
    templateUrl: './card-options-select.component.html',
    styleUrls: ['./card-options-select.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: CardOptionsSelectComponent,
        },
    ],
})
export class CardOptionsSelectComponent implements ControlValueAccessor {
    public selectedItem: CardOptionItem = null;

    #items: Array<CardOptionItem> = [];

    @Input() set items(value: Array<CardOptionItem>) {
        this.#items = value;
        this.selectedItem = this.#items.find((x) => x.value === this.value);
    }

    get items(): Array<CardOptionItem> {
        return this.#items;
    }

    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onTouched = () => {};

    // eslint-disable-next-line @typescript-eslint/no-empty-function, @typescript-eslint/no-unused-vars
    onChange = (value: unknown) => {};

    touched = false;

    disabled = false;

    value: string = null;

    writeValue(obj: string): void {
        this.value = obj;
        this.selectedItem = this.items.find((item) => item.value === obj);
    }
    registerOnChange(fn: any): void {
        this.onChange = fn;
    }
    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }
    setDisabledState?(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

    public selectItem(item: CardOptionItem) {
        if (this.disabled) {
            return;
        }
        this.value = item.value;
        this.selectedItem = item;
        this.onChange(item.value);
        if (!this.touched) {
            this.onTouched();
        }
    }
}
