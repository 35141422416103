<div class="d-flex align-items-center justify-content-center w-100">
    <div *ngIf="match.eloPoints.length === 2; else emptyAvatar" class="avatar d-none d-md-flex justify-content-center align-items-center">
        <app-match-box-elo-points [elo1]="match.eloPoints[0]" [expectedOutcome]="match.expectedOutcome.teamA"></app-match-box-elo-points>
    </div>

    <div class="line">
        <div class="top-left" [style.width.%]="leftLinePercent"></div>
        <div class="top-right" [style.width.%]="rightLinePercent"></div>
        <div class="circle" [style.left.%]="leftLinePercent"></div>

        <div class="match-grid align-items-center">
            <div class="name">
                {{ match.players[0].displayName }}
            </div>

            <div class="d-flex flex-row align-self-center">
                <span class="count" [class.dark]="match.teamAPoints > match.teamBPoints">{{ teamAWinnedSets }}</span>
                <div class="colon">:</div>
                <span class="count" [class.dark]="match.teamAPoints < match.teamBPoints">{{ teamBWinnedSets }}</span>
            </div>

            <div class="name">
                {{ match.players[1].displayName }}
            </div>
        </div>
    </div>
    <div *ngIf="match.eloPoints.length === 2; else emptyAvatar" class="avatar d-none d-md-flex justify-content-center align-items-center">
        <app-match-box-elo-points [elo1]="match.eloPoints[1]" [expectedOutcome]="match.expectedOutcome.teamB"></app-match-box-elo-points>
    </div>
</div>

<ng-template #emptyAvatar>
    <div class="avatar"></div>
</ng-template>
