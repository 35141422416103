<div [ngClass]="{ 'sidenav-view': !fullPageView }">
    <div class="mt-3">
        <h1 *ngIf="!fullPageView" class="text-center mb-1 mt-4">{{ 'SIDEBAR.BELL_HEADER' | translate }}</h1>
        <div class="d-flex flex-wrap mb-1 mx-2 justify-content-between">
            <div>
                <small
                    [ngClass]="{ 'link-secondary': bellNotificationFilter === 'unread', 'link-primary': bellNotificationFilter === 'all' }"
                    class="me-2 link-primary text-decoration-none clickable"
                    (click)="reloadNotifications('all')"
                    >{{ 'SIDEBAR.BELL_FILTER_ALL' | translate }} ({{ notificationsCounts.bellCount | badgeNumber }})</small
                >
                <small
                    [ngClass]="{ 'link-secondary': bellNotificationFilter === 'all', 'link-primary': bellNotificationFilter === 'unread' }"
                    class="text-decoration-none clickable"
                    (click)="reloadNotifications('unread')"
                    >{{ 'SIDEBAR.BELL_FILTER_UNREAD' | translate }} ({{ notificationsCounts.bellUnreadCount }})</small
                >
            </div>
            <small class="clickable link-secondary" (click)="markAllAsRead()">[{{ 'SIDEBAR.BELL_MARK_ALL_AS_READ' | translate }}]</small>
        </div>
        <div
            class="cards-wrapper"
            infiniteScroll
            [infiniteScrollDistance]="2"
            [scrollWindow]="fullPageView"
            [infiniteScrollThrottle]="200"
            (scrolled)="loadNextBellNotifications()"
        >
            <div class="card m-2" *ngIf="showPushNotificaionSubbscribe">
                <div class="card-body">
                    <h5 class="card-title">{{ 'PUSH_NOTIFICATIONS.SUBSCRIBE_CTA_TITLE' | translate: { gender: user.gender } }}</h5>
                    <div class="card-text">
                        {{ 'PUSH_NOTIFICATIONS.SUBSCRIBE_CTA_BODY' | translate }}
                        <ul>
                            <li>{{ 'PUSH_NOTIFICATIONS.SUBSCRIBE_CTA_BODY_POINT1' | translate }}</li>
                            <li>{{ 'PUSH_NOTIFICATIONS.SUBSCRIBE_CTA_BODY_POINT2' | translate: { gender: user.gender } }}</li>
                            <li>{{ 'PUSH_NOTIFICATIONS.SUBSCRIBE_CTA_BODY_POINT3' | translate }}</li>
                        </ul>
                    </div>
                    <div class="d-flex flex-column">
                        <a (click)="subscribeToPushNotifications()" href="javascript:void(0)">{{
                            'PUSH_NOTIFICATIONS.SUBSCRIBE_CTA_BODY_BTN_SUBSCRIBE' | translate
                        }}</a>
                        <a href="javascript:void(0)" (click)="openPushNotificationsSettings()">{{
                            'PUSH_NOTIFICATIONS.SUBSCRIBE_CTA_BODY_BTN_SETTINGS' | translate
                        }}</a>
                    </div>
                </div>
            </div>
            <div *ngIf="(matchInvatations$ | async).length">
                <app-match-invitation-card *ngFor="let n of matchInvatations$ | async" [matchInvatation]="n"></app-match-invitation-card>
            </div>
            <app-notification-card *ngFor="let n of notifications" [notification]="n"></app-notification-card>
        </div>
    </div>
</div>
<ng-template #emptySidebar>
    <div
        *ngIf="(matchInvatations$ | async).length === 0 && notifications.length === 0"
        class="d-flex flex-column justify-content-center align-items-center h-100"
    >
        <h3>{{ 'SIDEBAR.NO_NOTIFICATIONS' | translate }}</h3>
    </div>
</ng-template>
