<h1 mat-dialog-title *ngIf="!match" class="text-center">
    {{ 'ADD_MATCH.SELECT_PLAYERS' | translate }}
</h1>
<h1 mat-dialog-title *ngIf="match && match.started" class="text-center">
    {{ 'ADD_MATCH.SCORE' | translate }}
</h1>
<h1 mat-dialog-title *ngIf="match && !match.started" class="text-center">
    {{ 'ADD_MATCH.START_SET' | translate: { currentSet: match.sets.length + 1 } }}
</h1>

<div mat-dialog-content class="pb-0">
    <div class="toolbar">
        <swal
            #saveSwal
            [title]="'ADD_MATCH.SAVE_MATCH' | translate"
            (confirm)="saveActiveMatch($event)"
            [input]="'text'"
            [inputValue]="'ADD_MATCH.SAVE_MATCH_DEFAULT_NAME' | translate"
            [showCancelButton]="true"
        ></swal>

        <button color="warn" [swal]="dismissSwal" class="toolbar-close d-block d-md-none" mat-mini-fab>
            <mat-icon class="mt-1">close</mat-icon>
        </button>

        <div class="d-flex flex-row flex-wrap gap-2 justify-content-center justify-content-md-end mb-2 mb-md-0 mt-1">
            <button *ngIf="!match" mat-mini-fab (click)="togglePlayersSearchBar()" [matTooltip]="'ADD_MATCH.SEARCH' | translate">
                <mat-icon>search</mat-icon>
            </button>
            <button
                (click)="openSavedMatchesDialog()"
                *ngIf="!match && savedMatchesCount > 0"
                mat-mini-fab
                color="primary"
                [matTooltip]="'ADD_MATCH.LOAD_SAVED_MATCH' | translate"
            >
                <mat-icon [matBadge]="savedMatchesCount">open_in_browser</mat-icon>
            </button>
            <button
                [swal]="saveSwal"
                *ngIf="match?.started && timers?.paused"
                mat-mini-fab
                color="primary"
                [matTooltip]="'ADD_MATCH.SAVE_MATCH_TOOLTIP' | translate"
            >
                <mat-icon>save</mat-icon>
            </button>
            <button
                (click)="undoLastGoal()"
                *ngIf="
                    match?.started &&
                    settings.scoreEnteringMode === scoreEnteringMode.Course &&
                    (match.sets.at(-1)?.goals.length || match.sets.length > 1)
                "
                mat-mini-fab
                color="accent"
                [matTooltip]="'ADD_MATCH.UNDO_LAST_GOAL' | translate"
            >
                <mat-icon>undo</mat-icon>
            </button>
            <button
                (click)="toggleTimerPause()"
                *ngIf="match?.started && !timers?.paused"
                mat-mini-fab
                color="accent"
                [matTooltip]="'ADD_MATCH.PAUSE_MATCH' | translate"
            >
                <mat-icon>pause</mat-icon>
            </button>
            <button
                (click)="toggleTimerPause()"
                *ngIf="match?.started && timers?.paused"
                mat-mini-fab
                color="primary"
                [matTooltip]="'ADD_MATCH.RESUME_MATCH' | translate"
            >
                <mat-icon>play_arrow</mat-icon>
            </button>

            <button *ngIf="!match" mat-mini-fab (click)="sendInviteNotification()" [matTooltip]="'ADD_MATCH.SEND_INVITE' | translate">
                <mat-icon>outgoing_mail</mat-icon>
            </button>
            <button *ngIf="!match" mat-mini-fab (click)="openGameSettings()" [matTooltip]="'ADD_MATCH.OPEN_SETTINGS' | translate">
                <mat-icon>settings</mat-icon>
            </button>

            <button
                *ngIf="match && match.started && match.settings.gameMode !== gameMode.OneAgainstOne"
                [disabled]="!playerSwitchEnabled()"
                class="settings-link"
                mat-mini-fab
                (click)="setStartedFalse()"
                [matTooltip]="'ADD_MATCH.CHANGE_PLAYERS' | translate"
            >
                <mat-icon>sync</mat-icon>
            </button>

            <button
                color="warn"
                [swal]="dismissSwal"
                class="d-none d-md-block"
                mat-mini-fab
                [matTooltip]="'ADD_MATCH.CLOSE_MATCH' | translate"
            >
                <mat-icon class="mt-1">close</mat-icon>
            </button>
        </div>

        <swal
            #dismissSwal
            title="{{ 'ADD_MATCH.DISMISS_CONFIRM' | translate }}"
            icon="question"
            [showCancelButton]="true"
            [focusConfirm]="true"
            (confirm)="dismiss()"
        >
        </swal>

        <mat-menu #settingsMenu="matMenu">
            <button (click)="sendInviteNotification()" mat-menu-item>
                <mat-icon>notifications</mat-icon>{{ 'ADD_MATCH.SEND_JOIN_REQUEST' | translate }}
            </button>
        </mat-menu>
    </div>

    <div *ngIf="timers && match?.started" class="d-flex justify-content-center gap-1 timers mb-1 mb-lg-3">
        <mat-icon>schedule </mat-icon>
        <span> {{ timers.fromMatchStart | timespan: 'hh?:mm:ss' }} </span><span>{{ timers.fromSetStart | timespan: 'hh?:mm:ss' }}</span
        ><span *ngIf="settings?.scoreEnteringMode === 'course'">{{ timers.fromLastGoal | timespan: 'hh?:mm:ss' }}</span>
    </div>
    <div *ngIf="!match" class="d-flex flex-column">
        <div class="input-group mb-3" *ngIf="showPlayersSearch">
            <input
                #searchBarInput
                type="text"
                class="form-control"
                [placeholder]="'ADD_MATCH.SEARCH_PLACEHOLDER' | translate"
                aria-label="Username"
                [(ngModel)]="playerSearchTerm"
                (ngModelChange)="playerSearchTerm$.next($event)"
                cdkFocusInitial
            />
            <span class="input-group-text"><i class="fas fa-search"></i></span>
        </div>
        <app-new-match-players-mode
            class="align-self-center mb-2"
            [value]="settings.gameMode"
            (valueChange)="gameModeChanged($event)"
        ></app-new-match-players-mode>
        <div
            class="row section"
            *ngIf="settings?.playerSelectionMode === playerSelectionMode.Players || settings?.gameMode === gameMode.OneAgainstOne"
        >
            <app-chip
                *ngFor="let p of filteredPlayers; let ix = index"
                [selected]="isSelected(p)"
                [disabled]="p.matchInvitationRefused"
                (selectedChange)="selectPlayer(p)"
                class="col-12 col-md-4"
                [id]="'chip_' + ix"
            >
                {{ p.displayName }}
            </app-chip>
        </div>
        <div
            class="row section"
            *ngIf="settings?.playerSelectionMode === playerSelectionMode.NamedTeams && settings?.gameMode === gameMode.TwoAgainstTwo"
        >
            <app-chip
                *ngFor="let p of filteredNamedTeams; let ix = index"
                [selected]="isTeamSelected(p)"
                (selectedChange)="selectTeam(p)"
                class="col-12 col-md-4 px-2 pb-2"
                [id]="'chip_' + ix"
            >
                {{ p.teamName }}
            </app-chip>
        </div>
    </div>
    <div *ngIf="settings && match">
        <app-match-score
            *ngIf="settings?.scoreEnteringMode === 'score'"
            [match]="match"
            [autoTeamBalance]="autoTeamBalance"
        ></app-match-score>
        <div *ngIf="settings?.scoreEnteringMode === 'course'">
            <app-match-course *ngIf="match.started; else startMatch"></app-match-course>
            <ng-template #startMatch>
                <app-start-match-set></app-start-match-set>
            </ng-template>
        </div>
    </div>
</div>
