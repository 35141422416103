import { createSelector } from '@ngrx/store';
import { TeamMemberRole } from 'models';
import { AppState } from 'store';
import { IPlayer } from './players.model';

export const selectAllPlayers = (state: AppState) => state.players.all;

export const selectSettings = (state: AppState) => state.players.teamSettings;

export const selectPlayeblePlayers = createSelector(selectAllPlayers, (all: Array<IPlayer>) => {
    return all.filter((x) => x.teamMemberRole === TeamMemberRole.Player || x.teamMemberRole === TeamMemberRole.Guest);
});
