<mat-table [dataSource]="goals" class="mat-elevation-z8">
    <ng-container matColumnDef="playerA">
        <mat-cell *matCellDef="let element"> {{ element.playerA }} </mat-cell>
    </ng-container>
    <ng-container matColumnDef="roleA">
        <mat-cell *matCellDef="let element">
            <span *ngIf="element.playerA" class="text-muted">
                {{ element.elapsedSeconds | timespan: 'm:ss' }}
                <i
                    class="fas fa-futbol"
                    [class.fa-futbol]="element.role === setScoreGoalRole.Offense"
                    [class.fa-shield-alt]="element.role === setScoreGoalRole.Defense"
                ></i>
            </span>
        </mat-cell>
    </ng-container>
    <ng-container matColumnDef="score">
        <mat-cell *matCellDef="let element">
            <span [class.score-dark]="element.scoreA < element.scoreB">{{ element.scoreA }}</span>
            :
            <span [class.score-dark]="element.scoreA > element.scoreB">{{ element.scoreB }}</span></mat-cell
        >
    </ng-container>
    <ng-container matColumnDef="roleB">
        <mat-cell *matCellDef="let element">
            <span *ngIf="element.playerB" class="text-muted">
                <i
                    class="fas fa-futbol"
                    [class.fa-futbol]="element.role === setScoreGoalRole.Offense"
                    [class.fa-shield-alt]="element.role === setScoreGoalRole.Defense"
                ></i>
                {{ element.elapsedSeconds | timespan: 'm:ss' }}
            </span>
        </mat-cell>
    </ng-container>
    <ng-container matColumnDef="playerB">
        <mat-cell *matCellDef="let element"> {{ element.playerB }} </mat-cell>
    </ng-container>

    <mat-row *matRowDef="let row; columns: ['playerA', 'roleA', 'score', 'roleB', 'playerB']"></mat-row>
</mat-table>
