import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { CurrentUserModel } from 'models';
import { Observable, ReplaySubject, catchError, map, of, tap } from 'rxjs';
import { AppState, playersActions, userActions } from 'store';
import { loadPlayers } from 'store/players/players.actions';
import { loadSeasons } from 'store/season/season.actions';
import { loadMatchInvitations } from 'store/sidebar/sidebar.actions';
import { BrowserStorageService, storageKeys } from './browser-storage.service';
import { memoryCache } from './http-memory-cache';
import { PushNotificationService } from './push-notifications.service';
import { TokenService } from './token.service';
import { User } from './user';
import { EMPTY } from './utils';

@Injectable({ providedIn: 'root' })
export class Session {
    constructor(
        private readonly http: HttpClient,
        private readonly pushService: PushNotificationService,
        private readonly store: Store<AppState>,
        private readonly storage: BrowserStorageService,
        private readonly tokenService: TokenService,
        private readonly router: Router,
    ) {}

    public user = new User();

    #userInitialized = new ReplaySubject<User>(1);
    public userInitialized = this.#userInitialized.asObservable();

    public isSubbscribedToPushNotifications = false;

    public initialize(): Observable<boolean> {
        return this.http.get<CurrentUserModel>('/api/account/me').pipe(
            map((user) => {
                this.initializeApplication(user);
                return true;
            }),
            memoryCache('currentUser'),
            catchError(() => of(false)),
            tap((res) => {
                if (res) {
                    this.#userInitialized.next(this.user);
                }
            }),
        );
    }

    public reinitialize() {
        return this.http.get<CurrentUserModel>('/api/account/me').pipe(
            map((user) => {
                this.initializeApplication(user);
                return true;
            }),
            catchError(() => of(false)),
            tap((res) => {
                if (res) {
                    this.#userInitialized.next(this.user);
                }
            }),
        );
    }

    private initializeApplication(user: CurrentUserModel) {
        this.user.isAuthenticated = true;
        Object.assign(this.user, user);
        this.store.dispatch(userActions.setCurrentUser({ user }));

        const browserId = this.storage.local.getItem(storageKeys.browserId);
        this.isSubbscribedToPushNotifications = user.notificationSubscriptions.some((x) => x.browserId === browserId);
        if (this.isSubbscribedToPushNotifications) {
            this.pushService.renewPushNotificationSubscription(this.user).catch(EMPTY);
        }

        this.store.dispatch(playersActions.loadSettings());
        this.store.dispatch(loadSeasons());
        this.store.dispatch(loadMatchInvitations({ lastModified: null }));
        this.store.dispatch(loadPlayers({ force: true }));
    }

    public get pushNotificatiosSupported(): boolean {
        return this.pushService.pushNotificatiosSupported;
    }

    public switchTeam(idTeam: number) {
        this.http.get(`/api/account/switch-team/${idTeam}`).subscribe(() => (window.location.href = '/dashboard'));
    }

    public logout() {
        this.http.get('/api/account/logout').subscribe(() => {
            this.tokenService.clearTokens();
            this.router.navigate(['/account/login']).catch(EMPTY);
        });
    }
}
