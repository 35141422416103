import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { NgForm, UntypedFormControl, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { SwalComponent } from '@sweetalert2/ngx-sweetalert2';
import { FormGroup, TypedFormBuilder } from 'core/forms';
import { EMPTY } from 'core/utils';
import { IssueInviteTokenModel, SendInviteEmailModel } from 'models';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AppState, playersActions } from 'store';

interface IUnregisteredPlayer {
    displayName: string;
}
@Component({
    selector: 'app-invite-form',
    templateUrl: './invite-form.component.html',
    styleUrls: ['./invite-form.component.scss'],
})
export class InviteFormComponent implements OnInit {
    public inviteLink$: Observable<string>;

    public email = new UntypedFormControl(null, [Validators.required, Validators.email]);
    public unregisteredPlayer: FormGroup<IUnregisteredPlayer>;

    @ViewChild('firstNameInput') firstNameInput: ElementRef<HTMLInputElement>;

    @Input() idPlayer: number;

    constructor(
        private readonly http: HttpClient,
        private readonly snackBar: MatSnackBar,
        private readonly translate: TranslateService,
        private readonly store: Store<AppState>,
        fb: TypedFormBuilder,
    ) {
        this.unregisteredPlayer = fb.group<IUnregisteredPlayer>({
            displayName: [null, Validators.required],
        });
    }

    ngOnInit() {
        this.inviteLink$ = this.http.get<IssueInviteTokenModel>('/api/invite-token', { params: { idPlayer: this.idPlayer || '' } }).pipe(
            map((x) => {
                const port = window.location.port ? ':' + window.location.port : '';
                return `https://${window.location.hostname}${port}/invite/${x.token}`;
            }),
        );
    }

    public copyInviteLink(input: HTMLInputElement) {
        navigator.clipboard
            .writeText(input.value)
            .then(() => {
                this.snackBar.open(this.translate.instant('SETTINGS.COPIED_TO_CLIPBOARD'), null, { duration: 2000 });
            })
            .catch(EMPTY);
    }

    public sendInviteEmail(swal: SwalComponent, form: NgForm) {
        if (this.email.invalid) {
            this.email.markAsTouched();
            return;
        }
        const model: SendInviteEmailModel = {
            to: this.email.value,
            idUnregisteredPlayer: this.idPlayer,
        };
        this.http.post('/api/teams/send-invite-email', model).subscribe(() => {
            swal.fire().then(EMPTY, EMPTY);
            form.resetForm();
            this.email.reset();
        });
    }

    public createUnregistered(swal: SwalComponent, form: NgForm) {
        if (this.unregisteredPlayer.invalid) {
            this.unregisteredPlayer.markAllAsTouched();
            return;
        }
        this.http.post('/api/account/unregistered-player', this.unregisteredPlayer.value).subscribe(() => {
            swal.fire().then(EMPTY, EMPTY);
            this.unregisteredPlayer.reset();
            form.resetForm();
            this.store.dispatch(playersActions.loadPlayers({ force: true }));
        });
    }
}
