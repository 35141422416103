import { Component, EventEmitter, Input, Output } from '@angular/core';
import { GameMode } from 'models';

@Component({
    selector: 'app-new-match-players-mode',
    templateUrl: './new-match-players-mode.component.html',
    styleUrls: ['./new-match-players-mode.component.scss'],
})
export class GameModeComponent {
    public gameMode = GameMode;

    @Input() public value: GameMode;

    @Output() public valueChange = new EventEmitter<GameMode>();
}
