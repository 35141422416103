import { ChangeDetectorRef, Pipe, PipeTransform } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { TranslateService } from '@ngx-translate/core';
import { Duration } from 'luxon';

@Pipe({
    name: 'timespan',
    pure: false,
})
export class TimespanPipe implements PipeTransform {
    constructor(
        private readonly translate: TranslateService,
        private readonly ref: ChangeDetectorRef,
    ) {
        this.translate.onLangChange.pipe(takeUntilDestroyed()).subscribe(() => {
            this.ref.markForCheck();
        });
    }

    transform(value: number, format?: 'hh?:mm:ss' | 'm:ss' | 'long'): unknown {
        if (value === null || value === undefined) {
            return value;
        }
        let duration = Duration.fromMillis(value * 1000).rescale();
        switch (format) {
            case 'hh?:mm:ss':
                if (duration.hours) {
                    return duration.toFormat('hh:mm:ss');
                } else {
                    return duration.toFormat('mm:ss');
                }
            case 'm:ss':
                return duration.toFormat('m:ss');
            case 'long':
            default:
                duration = duration.shiftTo('hours', 'minutes', 'seconds');
                return this.translate.instant('COMMON.TIMESPAN_FORMAT', { hours: Math.floor(duration.hours), minutes: duration.minutes });
        }
    }
}
