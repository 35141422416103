<ng-template #emptyMainScoreboard>
    <div *ngIf="items !== null && items.length === 0" class="d-flex flex-column justify-content-center align-items-center empty-message">
        <h3>{{ 'DASHBOARD.EMPTY_SCOREBOARD' | translate }}</h3>
    </div>
</ng-template>

<div class="fsTable scoreboard mat-elevation-z8">
    <div class="fsTable_header scoreboard_header" *ngIf="items">
        <div
            class="fsTable_cell-head scoreboard_cell-name d-none d-md-flex align-items-center clickable"
            (click)="openHistory()"
            [matTooltip]="'COMMON.SHOW_SCOREBOARD_HISTORY' | translate"
        >
            <span>
                {{ 'STATISTICS.SCOREBOARD_ELO_TITLE' | translate }}
            </span>
            <mat-icon class="ms-2 text-themed">analytics</mat-icon>
        </div>
        <span class="fsTable_cell-head scoreboard_cell-name d-block d-md-none"> {{ 'STATISTICS.SCOREBOARD_ELO_TITLE' | translate }}</span>
        <div class="fsTable_cell-head scoreboard_cell-wins">{{ 'DASHBOARD.ELO_SCOREBOARD_POINTS' | translate }}</div>
    </div>

    <div
        class="box-wrapper scrollbar"
        [class.limit-height]="height === undefined"
        *ngIf="items?.length; else emptyMainScoreboard"
        [style.max-height.px]="height"
    >
        <div
            class="fsTable_line scoreboard_line"
            *ngFor="let p of items; let ix = index"
            [class.first]="ix === 0"
            [class.second]="ix === 1"
            [class.third]="ix === 2"
        >
            <div class="fsTable_cell scoreboard_cell-medal">
                <app-medal-img [position]="ix + 1" [medal]="getMedal(ix + 1, settings)"></app-medal-img>
            </div>
            <div class="fsTable_cell scoreboard_cell-name">
                <span class="">{{ ix + 1 }}. {{ p.displayName }}</span>
            </div>
            <div class="fsTable_cell scoreboard_cell-wins">
                {{ p.eloPoints }}
            </div>
        </div>

        <div
            [matTooltip]="'DASHBOARD.MATCHBOX_SETS_TRESHOLD' | translate: { threshold: p.remainsForQualification }"
            matTooltipTouchGestures="off"
            class="fsTable_line scoreboard_line text-muted"
            *ngFor="let p of notQualified"
        >
            <div class="fsTable_cell scoreboard_cell-medal"></div>
            <div class="fsTable_cell scoreboard_cell-name">
                <span class="">{{ p.displayName }}</span>
            </div>
            <!-- <div class="fsTable_cell scoreboard_cell-matches">{{ p.matchCount }}</div>

            <div class="fsTable_cell scoreboard_cell-winsPerc">
                {{ p.matchCount }}
            </div> -->
            <div class="fsTable_cell scoreboard_cell-wins">
                {{ p.eloPoints }}
            </div>
        </div>
    </div>
</div>
