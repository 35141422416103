<ng-template #emptyMainScoreboard>
    <div *ngIf="items !== null && items.length === 0" class="d-flex flex-column justify-content-center align-items-center empty-message">
        <h3>{{ 'DASHBOARD.EMPTY_SCOREBOARD' | translate }}</h3>
    </div>
</ng-template>

<div class="fsTable scoreboard mat-elevation-z8">
    <div class="fsTable_header scoreboard_header" *ngIf="items">
        <div
            class="fsTable_cell-head scoreboard_cell-name d-none d-md-flex align-items-center clickable"
            (click)="openHistory()"
            [matTooltip]="'COMMON.SHOW_SCOREBOARD_HISTORY' | translate"
        >
            <span>
                {{ title }}
            </span>
            <mat-icon class="ms-2 text-themed">analytics</mat-icon>
        </div>
        <span class="fsTable_cell-head scoreboard_cell-name d-block d-md-none"> {{ title }}</span>
        <div class="fsTable_cell-head scoreboard_cell-matches">
            {{ 'DASHBOARD.DEFOF_SETS' | translate }}
        </div>
        <div class="fsTable_cell-head scoreboard_cell-winsPerc">
            {{ 'DASHBOARD.DEFOF_GOALS' | translate }}
        </div>
        <div class="fsTable_cell-head scoreboard_cell-wins">{{ 'DASHBOARD.DEFOF_AVG_GOALS' | translate }}</div>
    </div>

    <div class="box-wrapper limit-height scrollbar" *ngIf="items?.length; else emptyMainScoreboard" [style.max-height.px]="height">
        <div
            class="fsTable_line scoreboard_line"
            *ngFor="let p of items; let ix = index"
            [class.first]="ix === 0"
            [class.second]="ix === 1"
            [class.third]="ix === 2"
        >
            <div class="fsTable_cell scoreboard_cell-medal">
                <app-medal-img [position]="ix + 1" [medal]="getMedal(ix + 1, settings)"></app-medal-img>
            </div>
            <div class="fsTable_cell scoreboard_cell-name">
                <span class="">{{ ix + 1 }}. {{ p.displayName }}</span>
            </div>
            <div class="fsTable_cell scoreboard_cell-matches">{{ p.totalSets }}</div>

            <div class="fsTable_cell scoreboard_cell-winsPerc">
                {{ p.totalGoals }}
            </div>
            <div
                class="fsTable_cell scoreboard_cell-wins"
                [matTooltip]="p.avgGoals | number: '1.0-5' : 'cs'"
                matTooltipPosition="above"
                matTooltipTouchGestures="off"
            >
                {{ p.avgGoals | number: '1.0-2' }}
            </div>
        </div>

        <div
            [matTooltip]="'DASHBOARD.MATCHBOX_SETS_TRESHOLD' | translate: { threshold: p.remainsForQualification }"
            class="fsTable_line scoreboard_line text-muted"
            matTooltipTouchGestures="off"
            *ngFor="let p of notQualified"
        >
            <div class="fsTable_cell scoreboard_cell-medal"></div>
            <div class="fsTable_cell scoreboard_cell-name">
                <span class="">{{ p.displayName }}</span>
            </div>
            <div class="fsTable_cell scoreboard_cell-matches">{{ p.totalSets }}</div>

            <div class="fsTable_cell scoreboard_cell-winsPerc">
                {{ p.totalGoals }}
            </div>
            <div class="fsTable_cell scoreboard_cell-wins" [matTooltip]="p.avgGoals | number: '1.0-5' : 'cs'" matTooltipPosition="above">
                {{ p.avgGoals | number: '1.0-2' }}
            </div>
        </div>
    </div>
</div>
