import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Season } from 'models';
import { map, switchMap } from 'rxjs/operators';
import { addSeason, deleteSeason, loadSeasons, loadSeasonsSuccess, updateSeason } from './season.actions';

@Injectable()
export class SeasonEffects {
    loadSeasons$ = createEffect(() =>
        this.actions$.pipe(
            ofType(loadSeasons),
            switchMap(() =>
                this.http
                    .get<{ values: Array<Season>; current: Season }>('/api/seasons')
                    .pipe(map((s) => loadSeasonsSuccess({ current: s.current, seasons: s.values }))),
            ),
        ),
    );

    addSeason$ = createEffect(() =>
        this.actions$.pipe(
            ofType(addSeason),
            switchMap((a) => this.http.post<Season>('/api/seasons', a.season).pipe(map(() => loadSeasons()))),
        ),
    );

    updateSeason$ = createEffect(() =>
        this.actions$.pipe(
            ofType(updateSeason),
            switchMap((a) => this.http.patch<Season>(`/api/seasons/${a.season.id}`, a.season).pipe(map(() => loadSeasons()))),
        ),
    );

    deleteSeason$ = createEffect(() =>
        this.actions$.pipe(
            ofType(deleteSeason),
            switchMap((action) => this.http.delete(`/api/seasons/${action.season.id}`).pipe(map(() => loadSeasons()))),
        ),
    );

    constructor(
        private readonly actions$: Actions,
        private readonly http: HttpClient,
    ) {}
}
