import { ApplicationTheme, BasicTeamModel, CurrentUserModel, Gender, OnboardingStatusModel, TeamMemberRole } from 'models';

export class User implements CurrentUserModel {
    idPlayer: number;
    idUser: string | null;
    idTeam: number;
    public teamName: string;
    public isAuthenticated = false;
    public displayName: string;
    public vapidPublicKey: string;
    public roles: Array<string> = [];
    public teams: Array<BasicTeamModel> = [];
    public gender: Gender;
    public teamMembersCount: number;
    public onboardingStatus: OnboardingStatusModel;
    public teamMemberRole: TeamMemberRole;
    public isMaintainer: boolean;

    public theme: ApplicationTheme;
    public isInRole(role: string) {
        return this.roles.indexOf(role) !== -1;
    }
}
