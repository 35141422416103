import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { GetMatchAchievementModel } from 'models';

@Component({
    selector: 'app-match-box-achievements',
    templateUrl: './match-box-achievements.component.html',
    styleUrls: ['./match-box-achievements.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MatchBoxAchievementsComponent {
    @Input() achievements: Array<GetMatchAchievementModel> = [];
}
