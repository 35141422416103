import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { Session } from 'core/session.service';
import { User } from 'core/user';
import { EMPTY } from 'core/utils';
import { BellNotificationCountModel, BellNotificationModel, MatchInvitationModel, MatchInvitationPlayerState } from 'models';
import { Observable, Subject, combineLatest } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { SubscribeToPushNotificationModalComponent } from 'shared/subscribe-to-push-notification-modal/subscribe-to-push-notification-modal.component';
import { AppState, sidebarActions } from 'store';
import { loadMatchInvitations, loadNextNotifications } from 'store/sidebar/sidebar.actions';
import { BellNotificationsFilterType } from 'store/sidebar/sidebar.models';
import { selectCounts, selectFilter, selectNotifications, selectUnreadCount } from 'store/sidebar/sidebar.reducer';

@Component({
    selector: 'app-sidebar',
    templateUrl: './sidebar.component.html',
    styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent implements OnInit, OnDestroy {
    @Input() fullPageView = false;

    public matchInvatations$: Observable<Array<MatchInvitationModel & { acceptedCount: number }>>;
    private _unsubscribe = new Subject<void>();
    public user: User;

    public notifications: Array<BellNotificationModel> = [];
    public notificationsCounts: BellNotificationCountModel;
    public bellNotificationFilter: BellNotificationsFilterType;

    public showLoadNext = true;
    private _lastNotificationCount = 0;

    public get showPushNotificaionSubbscribe() {
        return this.session.pushNotificatiosSupported && !this.session.isSubbscribedToPushNotifications;
    }

    constructor(
        private readonly store: Store<AppState>,
        private readonly session: Session,
        private readonly router: Router,
        private readonly dialog: MatDialog,
    ) {
        this.matchInvatations$ = store.pipe(
            select((x) => x.sidebar.matchInvitations),
            map((x: Array<MatchInvitationModel & { acceptedCount: number }>) => {
                x.forEach((i) => (i.acceptedCount = i.players.filter((f) => f.state === MatchInvitationPlayerState.Accepted).length));
                return x;
            }),
            takeUntil(this._unsubscribe),
        );
    }
    ngOnInit(): void {
        this.user = this.session.user;
        this.store.dispatch(loadMatchInvitations({ lastModified: null }));

        this.store.pipe(select(selectUnreadCount), takeUntil(this._unsubscribe)).subscribe(() => {
            this.store.dispatch(sidebarActions.loadNotifications({}));
        });

        combineLatest([
            this.store.pipe(select(selectNotifications)),
            this.store.pipe(select(selectCounts)),
            this.store.pipe(select(selectFilter)),
        ])
            .pipe(takeUntil(this._unsubscribe))
            .subscribe(([notifications, counts, filter]) => {
                switch (filter) {
                    case 'all':
                        this.showLoadNext = notifications.length < counts.bellCount;
                        break;
                    case 'unread':
                        this.showLoadNext = notifications.length < counts.bellUnreadCount;
                        break;
                    default:
                        this.showLoadNext = false;
                        break;
                }
            });

        this.store.pipe(select(selectNotifications), takeUntil(this._unsubscribe)).subscribe((res) => {
            if (this._lastNotificationCount !== 0 && this._lastNotificationCount === res.length) {
                this.showLoadNext = false;
            }
            this._lastNotificationCount = res.length;
            this.notifications = res;
        });

        this.store.pipe(select(selectCounts), takeUntil(this._unsubscribe)).subscribe((x) => (this.notificationsCounts = x));
        this.store.pipe(select(selectFilter), takeUntil(this._unsubscribe)).subscribe((x) => (this.bellNotificationFilter = x));
    }

    ngOnDestroy() {
        this._unsubscribe.next();
        this._unsubscribe.complete();
        if (this.bellNotificationFilter === 'all') {
            const unread = this.notifications.filter((x) => x.markedAsRead === false).map((x) => x.id);
            if (unread.length) {
                this.store.dispatch(sidebarActions.markAsRead({ ids: unread }));
            }
        }
    }

    public subscribeToPushNotifications() {
        this.dialog.open(SubscribeToPushNotificationModalComponent);
    }

    public openPushNotificationsSettings() {
        this.store.dispatch(sidebarActions.toggleSidebar());
        this.router.navigate(['settings', 'player']).catch(EMPTY);
    }

    public loadNextBellNotifications() {
        this.store.dispatch(loadNextNotifications());
    }

    public markAllAsRead() {
        this.store.dispatch(sidebarActions.markAllAsRead());
    }

    public reloadNotifications(filter: BellNotificationsFilterType) {
        this.store.dispatch(sidebarActions.loadNotifications({ filter }));
    }
}
