import { animate, style, transition, trigger } from '@angular/animations';
import { HttpClient } from '@angular/common/http';
import { ChangeDetectorRef, Component, ElementRef, Input, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { GetMatchModelExt } from 'core/entities';
import { Session } from 'core/session.service';
import { SignalRService } from 'core/signalr.service';
import { EMPTY } from 'core/utils';
import { GameMode, GetMatchPlayerModel, GetMatchSetModel, MatchEmojiType, MatchTeam } from 'models';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

const shameAudio = new Audio('/assets/audio/shame.mp3');

@Component({
    selector: 'app-match-box',
    templateUrl: './match-box.component.html',
    styleUrls: ['./match-box.component.scss'],
    animations: [
        trigger('toggleAnimation', [
            transition(':enter', [style({ opacity: 0, height: 0 }), animate('300ms ease-in', style({ opacity: 1, height: '*' }))]),
            transition(':leave', [style({ opacity: 1, height: '*' }), animate('300ms ease-in', style({ opacity: 0, height: 0 }))]),
        ]),
        trigger('fasterToggleAnimation', [
            transition(':enter', [style({ opacity: 0, height: 0 }), animate('200ms ease-in', style({ opacity: 1, height: '*' }))]),
            transition(':leave', [style({ opacity: 1, height: '*' }), animate('200ms ease-in', style({ opacity: 0, height: 0 }))]),
        ]),
    ],
})
export class MatchBoxComponent implements OnInit, OnDestroy {
    #unsubscribe = new Subject<void>();

    public matchEmojiType = MatchEmojiType;

    public selectedSet = -1;
    public gameMode = GameMode;

    public expA = 0;
    public expB = 0;
    public showComments = false;
    public showAchievements = false;
    public hoveredEmoji: MatchEmojiType = null;
    public emojies: Record<string, string> = {};

    public idPlayer: number;
    private _match: GetMatchModelExt;
    @Input() public set match(value: GetMatchModelExt) {
        this._match = value;
        if (value) {
            this.expA = value.players.find((p) => p.team === MatchTeam.A).experiencePoints;
            this.expB = value.players.find((p) => p.team === MatchTeam.B).experiencePoints;
        }
    }

    public get match() {
        return this._match;
    }

    constructor(
        private readonly dialog: MatDialog,
        private readonly http: HttpClient,
        private readonly signalR: SignalRService,
        private readonly session: Session,
        private readonly element: ElementRef<HTMLElement>,
        private readonly changeDetecror: ChangeDetectorRef,
    ) {
        this.idPlayer = session.user.idPlayer;
    }

    ngOnInit() {
        this.signalR.emojiChanged$
            .pipe(
                takeUntil(this.#unsubscribe),
                filter((x) => this.match && x.idMatch === this.match.id),
            )
            .subscribe(({ data }) => {
                this.match.emojies = data;
            });

        this.signalR.matchCommentsChanged$
            .pipe(
                takeUntil(this.#unsubscribe),
                filter((x) => this.match && x.idMatch === this.match.id),
            )
            .subscribe(({ count }) => {
                this.match.commentsCount = count;

                this.loadComments();
            });

        this.signalR.achievementsChanged$
            .pipe(
                takeUntil(this.#unsubscribe),
                filter((x) => this.match && x.idMatch === this.match.id),
            )
            .subscribe(({ achievements }) => {
                this.match.achievements = achievements;
            });
    }

    ngOnDestroy() {
        this.#unsubscribe.next();
        this.#unsubscribe.complete();
    }

    public isShameMatch(match: GetMatchModelExt) {
        return match.sets.some((x) => x.scoreTeamA === 0 || x.scoreTeamB === 0);
    }

    public getRoleIcon(set: GetMatchSetModel, player: GetMatchPlayerModel) {
        if (set.idPlayerTeamADefense === player.idPlayer || set.idPlayerTeamBDefense === player.idPlayer) {
            return 'fa-shield-alt';
        } else {
            return 'fa-futbol';
        }
    }

    matchClick(match: GetMatchModelExt) {
        if (!this.isShameMatch(match)) {
            return;
        }
        shameAudio.play().catch(EMPTY);
    }

    public getEmojiCount(type: MatchEmojiType) {
        return this.match.emojies.filter((x) => x.type === type).length;
    }

    public onEmojiClick(event: MouseEvent, type: MatchEmojiType) {
        event.stopPropagation();
        this.http
            .post<{ values: Array<any> }>(`/api/matches/${this.match.id}/emojies`, {
                type: type,
                createdAt: new Date().toISOString(),
            })
            .subscribe((res) => {
                this.match.emojies = res.values;
            });
    }

    public onCommentsClick() {
        this.showComments = !this.showComments;
        this.showAchievements = false;
        if (!this.showComments) {
            this.selectedSet = -1;
        }
    }

    public onAchievementsClick() {
        this.showComments = false;
        this.selectedSet = -1;
        this.showAchievements = !this.showAchievements;
    }

    public loadComments() {
        this.http.get<any>(`/api/matches/${this.match.id}/comments`).subscribe((res) => {
            this.match.comments = res.values;
        });
    }

    public getEmojiPlayers(type: MatchEmojiType) {
        const res = this.match.emojies
            .filter((x) => x.type === type)
            .map((x) => x.playerDisplayName)
            .join(', ');

        return res;
    }

    public isEmojiActive(type: MatchEmojiType) {
        return this.match.emojies.some((x) => x.type === type && x.idPlayer === this.session.user.idPlayer);
    }

    public scrollIntoView() {
        this.element.nativeElement.scrollIntoView({ behavior: 'smooth' });
        setTimeout(() => {
            this.showComments = true;
        }, 100);
    }

    public selectSet(ix: number) {
        this.selectedSet = this.selectedSet === ix ? -1 : ix;
        if (this.selectedSet >= 0) {
            this.showComments = false;
            this.showAchievements = false;
        }
    }
}
