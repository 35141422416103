import { Component, OnDestroy, OnInit } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { Subject, takeUntil } from 'rxjs';

@Component({
    selector: 'app-update-in-progress-alert',
    templateUrl: './update-in-progress-alert.component.html',
    styleUrls: ['./update-in-progress-alert.component.scss'],
})
export class UpdateInProgressAlertComponent implements OnInit, OnDestroy {
    public showAlert = false;
    #unsubscribe = new Subject<void>();

    constructor(private readonly swUpdate: SwUpdate) {}

    ngOnInit(): void {
        if (this.swUpdate.isEnabled) {
            this.swUpdate.versionUpdates.pipe(takeUntil(this.#unsubscribe)).subscribe((event) => {
                if (event.type === 'VERSION_DETECTED') {
                    this.showAlert = true;
                } else {
                    this.showAlert = false;
                }
            });
        }
    }

    ngOnDestroy() {
        this.#unsubscribe.next();
        this.#unsubscribe.complete();
    }
}
