import { Component, ElementRef, Inject, OnDestroy, OnInit, Optional, ViewChild } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { SignalRService } from 'core/signalr.service';

import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { BrowserStorageService, storageKeys } from 'core/browser-storage.service';
import { Session } from 'core/session.service';
import { EMPTY, removeDiacritics } from 'core/utils';
import { AutobalanceMode, GameMode, MatchPlayer, NamedPlayersTeam, PlayerSelectionMode, ScoreEnteringMode, TeamSettings } from 'models';
import { Subject, interval } from 'rxjs';
import { debounceTime, map, take, takeUntil, tap, withLatestFrom } from 'rxjs/operators';
import { AppState, matchesActions, playersActions, playersSelectors } from 'store';
import { undoLastGoal } from 'store/matches/matches.actions';
import { IMatch, IMatchTimers } from 'store/matches/matches.model';
import { selectActiveMatch, selectSavedCount, selectTimers } from 'store/matches/matches.reducers';
import { IPlayer, NamedPlayersTeamExt } from 'store/players/players.model';
import {
    ISendInvitationModalContext,
    ISendInvitationModalResult,
    SendInvitationModalComponent,
} from '../send-invitation-modal/send-invitation-modal.component';
import { LoadSavedMatchComponent } from './load-saved-match/load-saved-match.component';

export interface IAddMatchContext {
    players: Array<number>;
    matchInvitationId: string;
}

@Component({
    selector: 'app-add-new-match',
    templateUrl: './add-new-match.component.html',
    styleUrls: ['./add-new-match.component.scss'],
})
export class AddNewMatchComponent implements OnInit, OnDestroy {
    public players: Array<IPlayer> = [];
    public namedTeams: Array<NamedPlayersTeamExt>;

    public filteredPlayers: Array<IPlayer> = [];
    public filteredNamedTeams: Array<NamedPlayersTeamExt>;
    public match: IMatch;

    public autoTeamBalance = true;
    public settings: TeamSettings;

    private _selectedPlayers: [MatchPlayer, MatchPlayer, MatchPlayer, MatchPlayer] = [null, null, null, null];
    public matchInvitationId: string;

    private _unsubscribe = new Subject<void>();
    public showPlayersSearch = false;
    public savedMatchesCount = 0;

    public playerSearchTerm$ = new Subject<string>();
    public playerSearchTerm: string = null;

    public scoreEnteringMode = ScoreEnteringMode;
    public gameMode = GameMode;
    public playerSelectionMode = PlayerSelectionMode;
    public autobalanceMode = AutobalanceMode;

    @ViewChild('searchBarInput')
    public searchPlayersInput: ElementRef<HTMLInputElement>;

    public timers: IMatchTimers = {
        fromLastGoal: 0,
        fromMatchStart: 0,
        fromSetStart: 0,
        paused: false,
    };

    constructor(
        public readonly signalR: SignalRService,
        private readonly store: Store<AppState>,
        private readonly dialogRef: MatDialogRef<AddNewMatchComponent>,
        private readonly matDialog: MatDialog,
        private readonly storage: BrowserStorageService,
        private readonly session: Session,
        private readonly router: Router,
        @Optional() @Inject(MAT_DIALOG_DATA) private readonly context: IAddMatchContext,
    ) {}

    ngOnInit() {
        this.matchInvitationId = this.context?.matchInvitationId || null;
        this.store
            .pipe(
                select((x) => x.players.teamSettings),
                takeUntil(this._unsubscribe),
            )
            .subscribe((settings) => {
                this.autoTeamBalance = settings.autobalanceMode === AutobalanceMode.MatchCount;
                this.settings = { ...settings };
            });
        this.store.dispatch(playersActions.loadNamedTeams({ force: false }));
        this.store
            .pipe(
                select((x) => x.players.namedTeams),
                withLatestFrom(this.store.select((x) => x.season.active)),
                map(([namedTeams, activeSeason]) => {
                    const all = namedTeams.filter((x) => x.idSeason === null);
                    if (activeSeason?.id) {
                        const seasonal = namedTeams.filter((x) => x.idSeason === activeSeason.id);
                        all.forEach((a) => {
                            if (
                                !seasonal.some(
                                    (x) =>
                                        (x.idPlayer1 === a.idPlayer1 && x.idPlayer2 === a.idPlayer2) ||
                                        (x.idPlayer1 === a.idPlayer2 && x.idPlayer2 === a.idPlayer1),
                                )
                            ) {
                                seasonal.push(a);
                            }
                        });
                        return seasonal;
                    }
                    return all;
                }),
                takeUntil(this._unsubscribe),
            )
            .subscribe((x) => {
                this.namedTeams = x;
                this.filteredNamedTeams = x;
            });
        this.store
            .pipe(
                takeUntil(this._unsubscribe),
                select(playersSelectors.selectPlayeblePlayers),
                tap((x) => x.forEach((i) => (i.matchInvitationRefused = false))),
                withLatestFrom(this.store.select((x) => x.players.teamSettings)),
            )
            .subscribe(([players, teamSettings]) => {
                this.players = players;
                this.filteredPlayers = players;
                if (this.context?.players) {
                    this.context.players
                        .slice(0, 4)
                        .map((id) => this.players.find((p) => p.id == id))
                        .filter((x) => !!x)
                        .forEach((player) => {
                            this.selectPlayer(player);
                        });
                } else {
                    const me = this.players.find((x) => x.id === this.session.user.idPlayer);
                    if (me && teamSettings.playerSelectionMode === PlayerSelectionMode.Players) {
                        this.selectPlayer(me);
                    }
                }
            });
        this.store.dispatch(playersActions.loadPlayers({ force: false }));

        this.store.pipe(select(selectTimers), takeUntil(this._unsubscribe)).subscribe((timers) => {
            this.storage.session.setItem(storageKeys.activeMatchTimers, JSON.stringify(timers));
            return (this.timers = timers);
        });

        this.store
            .pipe(
                takeUntil(this._unsubscribe),
                select((x) => x.matches.active),
                tap((active) => {
                    if (active) {
                        this.storage.session.setItem(storageKeys.activeMatch, JSON.stringify(active));
                    }
                }),
            )
            .subscribe((active) => (this.match = active));

        interval(1000)
            .pipe(takeUntil(this._unsubscribe), withLatestFrom(this.store.pipe(select(selectActiveMatch))))
            .subscribe(([, match]) => {
                if (match?.started) {
                    this.store.dispatch(matchesActions.incrementTimers());
                }
            });

        this.store.pipe(select(selectSavedCount), takeUntil(this._unsubscribe)).subscribe((x) => (this.savedMatchesCount = x));
        this.store.dispatch(matchesActions.loadSavedMatchesCount());

        this.playerSearchTerm$
            .pipe(
                debounceTime(150),
                map((x) => removeDiacritics(x)),
            )
            .subscribe((term) => {
                if (this.settings.playerSelectionMode === PlayerSelectionMode.NamedTeams) {
                    if (!term) {
                        this.filteredNamedTeams = this.namedTeams;
                    } else {
                        this.filteredNamedTeams = this.namedTeams.filter((x) => x.normalizedTeamName.indexOf(term) !== -1);
                    }
                } else if (this.settings.playerSelectionMode === PlayerSelectionMode.Players) {
                    if (!term) {
                        this.filteredPlayers = this.players;
                    } else {
                        this.filteredPlayers = this.players.filter((x) => x.normalizedDisplayName.indexOf(term) !== -1);
                    }
                }
            });
    }

    ngOnDestroy() {
        this._unsubscribe.next();
        this._unsubscribe.complete();
        this.playerSearchTerm$.complete();
    }

    public isSelected(player: IPlayer) {
        return this._selectedPlayers.some((x) => x && x.id === player.id);
    }

    selectPlayer(player: IPlayer) {
        const p = this._selectedPlayers.find((x) => x && x.id === player.id);
        if (p) {
            const ix = this._selectedPlayers.indexOf(p);
            if (this.settings.gameMode == GameMode.OneAgainstOne) {
                if (ix === 0 || ix === 1) {
                    this._selectedPlayers[0] = null;
                    this._selectedPlayers[1] = null;
                } else {
                    this._selectedPlayers[2] = null;
                    this._selectedPlayers[3] = null;
                }
            } else {
                this._selectedPlayers[ix] = null;
            }
        } else {
            if (this.settings.gameMode === GameMode.OneAgainstOne) {
                if (this._selectedPlayers[0] === null) {
                    this._selectedPlayers[0] = { id: player.id, displayName: player.displayName };
                    this._selectedPlayers[1] = { id: player.id, displayName: player.displayName };
                } else {
                    this._selectedPlayers[2] = { id: player.id, displayName: player.displayName };
                    this._selectedPlayers[3] = { id: player.id, displayName: player.displayName };
                }
            } else {
                const ix = this._selectedPlayers.indexOf(null);
                this._selectedPlayers[ix] = { id: player.id, displayName: player.displayName };
            }
        }
        if (this._selectedPlayers.every((x) => !!x)) {
            this.store.dispatch(
                matchesActions.createActiveMatch({
                    teamA: [this._selectedPlayers[0], this._selectedPlayers[1]],
                    teamB: [this._selectedPlayers[2], this._selectedPlayers[3]],
                    matchSettings: this.settings,
                }),
            );
        }

        if (this.showPlayersSearch) {
            this.playerSearchTerm = null;
            this.filteredNamedTeams = this.namedTeams;
            this.filteredPlayers = this.players;
            setTimeout(() => {
                this.searchPlayersInput?.nativeElement.focus();
            });
        }
    }

    public isTeamSelected(team: NamedPlayersTeam) {
        return (
            (this._selectedPlayers[0]?.id === team.idPlayer1 && this._selectedPlayers[1]?.id === team.idPlayer2) ||
            (this._selectedPlayers[2]?.id === team.idPlayer1 && this._selectedPlayers[3]?.id === team.idPlayer2)
        );
    }

    selectTeam(team: NamedPlayersTeam) {
        if (this._selectedPlayers[0]?.id === team.idPlayer1 && this._selectedPlayers[1]?.id === team.idPlayer2) {
            this._selectedPlayers[0] = null;
            this._selectedPlayers[1] = null;
        } else {
            if (!this._selectedPlayers[0]) {
                this._selectedPlayers[0] = { id: team.idPlayer1, displayName: team.player1.displayName };
                this._selectedPlayers[1] = { id: team.idPlayer2, displayName: team.player2.displayName };
            } else {
                this._selectedPlayers[2] = { id: team.idPlayer1, displayName: team.player1.displayName };
                this._selectedPlayers[3] = { id: team.idPlayer2, displayName: team.player2.displayName };
            }
        }

        if (this._selectedPlayers.every((x) => !!x)) {
            this.store.dispatch(
                matchesActions.createActiveMatch({
                    teamA: [this._selectedPlayers[0], this._selectedPlayers[1]],
                    teamB: [this._selectedPlayers[2], this._selectedPlayers[3]],
                    matchSettings: this.settings,
                }),
            );
        }
    }

    public setStartedFalse() {
        this.store.dispatch(matchesActions.setStarted({ started: false }));
    }

    public dismiss() {
        this.store.pipe(select(selectActiveMatch), take(1)).subscribe((activeMatch) => {
            if (activeMatch) {
                this.store.dispatch(matchesActions.clearActiveMatch({ uniqueId: activeMatch.uniqueId }));
            }
            this.dialogRef.close();
        });
    }

    public sendInviteNotification() {
        this.matDialog.open<SendInvitationModalComponent, ISendInvitationModalContext, ISendInvitationModalResult>(
            SendInvitationModalComponent,
            {
                panelClass: 'dialog-lg',
                data: {
                    gameMode: this.settings.gameMode,
                },
            },
        );
        this.dismiss();
    }

    public openGameSettings() {
        this.router.navigate(['/settings/game-settings']).catch(EMPTY);
        this.dismiss();
    }

    public toggleTimerPause() {
        this.store.dispatch(matchesActions.toggleTimerPause());
    }

    public saveActiveMatch(value: string) {
        this.store.dispatch(matchesActions.saveOngoingActiveMatch({ name: value }));
        this.dismiss();
    }

    public openSavedMatchesDialog() {
        this.matDialog.open(LoadSavedMatchComponent, { panelClass: 'dialog-md' });
    }

    public undoLastGoal() {
        this.store.dispatch(undoLastGoal());
    }

    public gameModeChanged(value: GameMode) {
        this.settings.gameMode = value;
        this._selectedPlayers = [null, null, null, null];
    }

    public togglePlayersSearchBar() {
        this.showPlayersSearch = !this.showPlayersSearch;
        if (this.showPlayersSearch) {
            setTimeout(() => {
                this.searchPlayersInput.nativeElement.focus();
            });
        } else {
            this.playerSearchTerm$.next(null);
        }
    }

    public playerSwitchEnabled() {
        if (!this.match) {
            return false;
        }
        if (this.match.settings.gameMode === GameMode.OneAgainstOne) {
            return false;
        }

        const set = this.match.sets.at(-1);
        if (set && set.goals.length) {
            if (!this.settings.allowPositionSwitchingDuringSet) {
                return false;
            }
        }

        return true;
    }
}
