import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, switchMap } from 'rxjs/operators';

import { HttpClient } from '@angular/common/http';
import { userActions } from 'store';
import { loadLogins, loadLoginsSuccess, removeLogin } from './user.actions';

@Injectable()
export class UserEffects {
    setTheme$ = createEffect(() =>
        this.actions$.pipe(
            ofType(userActions.setTheme),
            switchMap((action) =>
                this.http
                    .patch(`/api/players/set-theme`, { theme: action.theme })
                    .pipe(map(() => userActions.setThemeSuccess({ theme: action.theme }))),
            ),
        ),
    );

    loadLogins$ = createEffect(() =>
        this.actions$.pipe(
            ofType(loadLogins),
            switchMap(() => this.http.get(`/api/account/me/logins`).pipe(map((res) => loadLoginsSuccess({ logins: res })))),
        ),
    );
    removeLogin$ = createEffect(() =>
        this.actions$.pipe(
            ofType(removeLogin),
            switchMap(({ provider }) => this.http.delete(`/external-login/${provider}`).pipe(map(() => loadLogins()))),
        ),
    );
    constructor(
        private actions$: Actions,
        private readonly http: HttpClient,
    ) {}
}
