import { createAction, props } from '@ngrx/store';
import { EloScoreboardModel, MainScoreboardItemModel, OffenseDefenseScoreboardModel, TeamScoreboardItemModel } from 'models';

export const loadMain = createAction('[Scoreboards] Load Main');
export const loadTeams = createAction('[Scoreboards] Load Team');
export const loadOffense = createAction('[Scoreboards] Load Offense');
export const loadDefenseGiven = createAction('[Scoreboards] Load Defense Given');
export const loadDefenseReceived = createAction('[Scoreboards] Load Defense Received');
export const loadElo = createAction('[Scoreboards] Load Elo');
export const loadMainSuccess = createAction('[Scoreboards] Load Main success', props<{ scoreboard: Array<MainScoreboardItemModel> }>());
export const loadTeamsSuccess = createAction('[Scoreboards] Load Team success', props<{ scoreboard: Array<TeamScoreboardItemModel> }>());
export const loadOffenseSuccess = createAction(
    '[Scoreboards] Load Offense success',
    props<{ scoreboard: Array<OffenseDefenseScoreboardModel> }>(),
);
export const loadDefenseGivenSuccess = createAction(
    '[Scoreboards] Load Defense Given success',
    props<{ scoreboard: Array<OffenseDefenseScoreboardModel> }>(),
);
export const loadDefenseReceivedSuccess = createAction(
    '[Scoreboards] Load Defense Received success',
    props<{ scoreboard: Array<OffenseDefenseScoreboardModel> }>(),
);
export const loadEloSuccess = createAction('[Scoreboards] Load Elo success', props<{ scoreboard: Array<EloScoreboardModel> }>());
