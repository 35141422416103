<div class="d-flex align-items-center justify-content-center w-100">
    <div *ngIf="match.eloPoints.length === 4; else emptyAvatar" class="avatar d-none d-md-flex justify-content-center align-items-center">
        <app-match-box-elo-points
            [elo1]="match.eloPoints[0]"
            [elo2]="match.eloPoints[1]"
            [expectedOutcome]="match.expectedOutcome.teamA"
        ></app-match-box-elo-points>
    </div>

    <div class="line">
        <div class="top-left" [style.width.%]="leftLinePercent"></div>
        <div class="top-right" [style.width.%]="rightLinePercent"></div>
        <div class="circle" [style.left.%]="leftLinePercent"></div>

        <div class="match-grid">
            <div class="names-grid-1 justify-content-center">
                <span class="roles">
                    <i class="fa-xs fas" [ngClass]="getRoleIcon(s, match.players[0])" *ngFor="let s of match.sets"></i>
                </span>
                <div class="name">
                    {{ match.players[0].displayName }}
                </div>
                <span class="roles">
                    <i class="fa-xs fas" [ngClass]="getRoleIcon(s, match.players[1])" *ngFor="let s of match.sets"></i>
                </span>
                <div class="name">
                    {{ match.players[1].displayName }}
                </div>
            </div>

            <div class="d-flex flex-row align-self-center">
                <span class="count" [class.dark]="match.teamAPoints > match.teamBPoints">{{ teamAWinnedSets }}</span>
                <div class="colon">:</div>
                <span class="count" [class.dark]="match.teamAPoints < match.teamBPoints">{{ teamBWinnedSets }}</span>
            </div>

            <div class="names-grid-2 justify-content-center">
                <div class="name">
                    {{ match.players[2].displayName }}
                </div>
                <span class="roles">
                    <i class="fa-xs fas" [ngClass]="getRoleIcon(s, match.players[2])" *ngFor="let s of match.sets"></i>
                </span>

                <div class="name">
                    {{ match.players[3].displayName }}
                </div>
                <span class="roles">
                    <i class="fa-xs fas" [ngClass]="getRoleIcon(s, match.players[3])" *ngFor="let s of match.sets"></i>
                </span>
            </div>
        </div>
    </div>
    <div *ngIf="match.eloPoints.length === 4; else emptyAvatar" class="avatar d-none d-md-flex justify-content-center align-items-center">
        <app-match-box-elo-points
            [elo1]="match.eloPoints[2]"
            [elo2]="match.eloPoints[3]"
            [expectedOutcome]="match.expectedOutcome.teamB"
        ></app-match-box-elo-points>
    </div>
</div>

<ng-template #emptyAvatar>
    <div class="avatar"></div>
</ng-template>
