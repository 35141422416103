import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, of, tap } from 'rxjs';
import { BrowserStorageService, storageKeys } from './browser-storage.service';

export interface ITokens {
    accessToken: string;
    refreshToken: string;
    expiresAt: string;
}

@Injectable({
    providedIn: 'root',
})
export class TokenService {
    #accessToken: string = null;

    constructor(
        private readonly storage: BrowserStorageService,
        private readonly http: HttpClient,
    ) {}

    public saveTokens(accessToken: string, refreshToken: string) {
        this.#accessToken = accessToken;
        this.storage.local.setItem(storageKeys.refreshToken, refreshToken);
    }

    public getAccessToken() {
        return this.#accessToken;
    }

    public getRefreshToken() {
        return this.storage.local.getItem(storageKeys.refreshToken);
    }

    public clearTokens() {
        this.#accessToken = null;
        this.storage.local.removeItem(storageKeys.refreshToken);
    }

    public clearAccessToken() {
        this.#accessToken = null;
    }

    public refreshAccessToken(): Observable<ITokens> {
        const refreshToken = this.getRefreshToken();
        if (!refreshToken) {
            return of(null);
        }
        return this.http.post<ITokens>('/api/token/refresh', { refreshToken }).pipe(
            tap((tokens) => {
                this.saveTokens(tokens.accessToken, tokens.refreshToken);
            }),
            catchError(() => of(null)),
        );
    }

    public mobileAppRedirect() {
        window.location.href = `fooswars://?access_token=${this.getAccessToken()}&refresh_token=${this.getRefreshToken()}`;
    }
}
