<div class="box-container" [style.height.px]="containerHeight" *ngIf="set">
    <div class="box offense mat-elevation-z4" matRipple (click)="addGoal(set.teamA.offense, setScoreGoalRole.Offense)">
        <div [fitText]="0.1" class="icon w-100"></div>
        <div class="info">
            <div [fitText]="0.2" class="number w-100">{{ getGoalCount(set.teamA.offense, setScoreGoalRole.Offense) }}</div>
            <div [fitText]="0.8" class="name w-100">{{ set.teamA.offense.displayName }}</div>
        </div>
    </div>
    <div class="box defense mat-elevation-z4" matRipple (click)="addGoal(set.teamA.defense, setScoreGoalRole.Defense)">
        <div [fitText]="0.1" class="icon w-100"></div>
        <div class="info">
            <div [fitText]="0.2" class="number w-100">{{ getGoalCount(set.teamA.defense, setScoreGoalRole.Defense) }}</div>
            <div [fitText]="0.8" class="name w-100">{{ set.teamA.defense.displayName }}</div>
        </div>
    </div>
    <div class="score">
        <div class="d-block w-100">
            <div class="table mt-0 mt-lg-3 mb-0 mb-lg-3">
                <div class="table_line">
                    <div class="table_cell table_cell-names">
                        <span class="d-none d-md-block">{{ set.teamA.defense.displayName }} / {{ set.teamA.offense.displayName }}</span>
                    </div>
                    <div class="table_cell table_cell-score" [class.dark]="set.teamA.score > set.teamB.score">
                        {{ set.teamA.score }}
                    </div>
                    <div class="table_cell table_cell-score text-center" [class.dark]="set.teamA.score < set.teamB.score">
                        {{ set.teamB.score }}
                    </div>
                    <div class="table_cell table_cell-names">
                        <span class="d-none d-md-block">{{ set.teamB.defense.displayName }} / {{ set.teamB.offense.displayName }}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="box defense mat-elevation-z4" matRipple (click)="addGoal(set.teamB.defense, setScoreGoalRole.Defense)">
        <div [fitText]="0.1" class="icon w-100"></div>
        <div class="info">
            <div [fitText]="0.2" class="number w-100">{{ getGoalCount(set.teamB.defense, setScoreGoalRole.Defense) }}</div>
            <div [fitText]="0.8" class="name w-100">{{ set.teamB.defense.displayName }}</div>
        </div>
    </div>
    <div class="box offense mat-elevation-z4" matRipple (click)="addGoal(set.teamB.offense, setScoreGoalRole.Offense)">
        <div [fitText]="0.1" class="icon w-100"></div>
        <div class="info">
            <div [fitText]="0.2" class="number w-100">{{ getGoalCount(set.teamB.offense, setScoreGoalRole.Offense) }}</div>
            <div [fitText]="0.8" class="name w-100">
                {{ set.teamB.offense.displayName }}
            </div>
        </div>
    </div>
</div>
