import { Component, OnInit, Input, ElementRef, ViewChild, AfterViewInit } from '@angular/core';
import { matchesActions, AppState } from 'store';
import { Store, select } from '@ngrx/store';
import { IMatch } from 'store/matches/matches.model';
import { take } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AddNewMatchComponent } from '../add-new-match.component';
import { MatDialogRef } from '@angular/material/dialog';
import { TeamSettings } from 'models';
import { isSetWon } from '../score-utils';

@Component({
    selector: 'app-match-score',
    templateUrl: './match-score.component.html',
    styleUrls: ['./match-score.component.scss'],
})
export class MatchScoreComponent implements OnInit, AfterViewInit {
    public scoreTeamA: number;
    public scoreTeamB: number;

    @ViewChild('txtScoreTeamA') txtScoreTeamA: ElementRef<HTMLInputElement>;
    @ViewChild('txtScoreTeamB') txtScoreTeamB: ElementRef<HTMLInputElement>;

    public settings: TeamSettings;

    @Input() match: IMatch;
    @Input() autoTeamBalance = true;
    constructor(
        private readonly store: Store<AppState>,
        private snackBar: MatSnackBar,
        private readonly dialogRef: MatDialogRef<AddNewMatchComponent>,
    ) {}

    ngOnInit(): void {
        this.store.dispatch(matchesActions.setStarted({ started: true }));
        this.store
            .pipe(
                select((x) => x.players.teamSettings),
                take(1),
            )
            .subscribe((settings) => {
                this.settings = settings;
            });
    }

    ngAfterViewInit() {
        this.txtScoreTeamA.nativeElement.focus();
    }

    public switchPlayers(team: 'A' | 'B') {
        this.store.dispatch(matchesActions.switchTeamRoles({ team: team }));
    }

    public scoreTeamAChange(value: number) {
        this.scoreTeamA = value;
        if (value > 1 && value <= this.settings.numberOfGoalsToWin) {
            this.txtScoreTeamB.nativeElement.focus();
        }
    }

    public scoreKeyUp(event: KeyboardEvent) {
        if (event.key === 'Enter') {
            if (this.scoreTeamA === undefined || this.scoreTeamB === undefined) {
                return;
            }
            if (!isSetWon(this.settings, this.scoreTeamA, this.scoreTeamB)) {
                this.snackBar.open('Koho se snažíš ojebat? Zadej to znova a správně!');
                return;
            }
            this.store.dispatch(matchesActions.addSetToActiveMatch({ scoreTeamA: this.scoreTeamA, scoreTeamB: this.scoreTeamB }));
            this.store.dispatch(matchesActions.clearTimers({ fromLastGoal: true, fromSetStart: true }));

            this.scoreTeamA = this.scoreTeamB = null;
            this.txtScoreTeamA.nativeElement.focus();

            this.store
                .pipe(
                    select((x) => x.matches.active),
                    take(1),
                )
                .subscribe((x) => {
                    const teamAWinnigs = x.sets.filter((s) => s.teamA.score > s.teamB.score).length;
                    const teamBWinnigs = x.sets.filter((s) => s.teamA.score < s.teamB.score).length;

                    if (this.settings.autoSwitchPlayersOnNextSet) {
                        if (teamAWinnigs === 1 && teamBWinnigs === 1) {
                            const teamAWin = x.sets.find((s) => s.teamA.score > s.teamB.score);
                            if (teamAWin.teamA.defense.id !== x.teamA[0].id) {
                                this.store.dispatch(matchesActions.switchTeamRoles({ team: 'A' }));
                            }

                            const teamBWin = x.sets.find((s) => s.teamA.score < s.teamB.score);
                            if (teamBWin.teamB.defense.id !== x.teamB[0].id) {
                                this.store.dispatch(matchesActions.switchTeamRoles({ team: 'B' }));
                            }
                        } else {
                            this.store.dispatch(matchesActions.switchTeamRoles({ team: 'AB' }));
                        }
                    }

                    if (teamAWinnigs === this.settings.numberOfWinnedSetsToWin || teamBWinnigs === this.settings.numberOfWinnedSetsToWin) {
                        this.store.dispatch(matchesActions.saveActiveMatch());
                        this.dialogRef.close(true);
                    }
                });
        }
    }
}
