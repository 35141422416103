import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store, select } from '@ngrx/store';
import { SignalRService } from 'core/signalr.service';
import { BellNotificationCountModel, BellNotificationModel, MatchInvitationModel, MatchInvitationUpdateModel } from 'models';
import { debounceTime, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { AppState } from 'store';
import {
    changeMatchInvitationState,
    getNotificationCount,
    getNotificationCountSuccess,
    loadMatchInvitations,
    loadMatchInvitationsSuccess,
    loadNextNotifications,
    loadNextNotificationsSuccess,
    loadNotifications,
    loadNotificationsSuccess,
    markAllAsRead,
    markAsRead,
    markAsReadSuccess,
    matchInvitationAddComment,
    matchInvitationAddCommentSuccess,
} from './sidebar.actions';
import { selectFilter, selectNotifications } from './sidebar.reducer';

@Injectable()
export class SidebarEffects {
    loadMatchInvitations$ = createEffect(() =>
        this.actions$.pipe(
            ofType(loadMatchInvitations),
            switchMap(() => {
                return this.http
                    .get<{ values: Array<MatchInvitationModel> }>('/api/match-invitations')
                    .pipe(map((s) => loadMatchInvitationsSuccess({ payload: s.values })));
            }),
        ),
    );

    loadNotifications$ = createEffect(() =>
        this.actions$.pipe(
            ofType(loadNotifications),
            withLatestFrom(this.store.pipe(select(selectFilter))),
            switchMap(([action, filter]) => {
                return this.http
                    .get<Array<BellNotificationModel>>('/api/bell-notifications', {
                        params: { skip: 0, take: 20, unreadOnly: (action.filter || filter) === 'unread' },
                    })
                    .pipe(map((s) => loadNotificationsSuccess({ values: s, filter: action.filter || filter })));
            }),
        ),
    );

    loadNextNotifications$ = createEffect(() =>
        this.actions$.pipe(
            ofType(loadNextNotifications),
            withLatestFrom(this.store.pipe(select(selectNotifications))),
            withLatestFrom(this.store.pipe(select(selectFilter))),
            switchMap(([[, notifications], filter]) => {
                return this.http
                    .get<Array<BellNotificationModel>>('/api/bell-notifications', {
                        params: { skip: notifications.length, take: 20, unreadOnly: filter === 'unread' },
                    })
                    .pipe(map((s) => loadNextNotificationsSuccess({ values: s })));
            }),
        ),
    );

    loadNotificationCount$ = createEffect(() =>
        this.actions$.pipe(
            ofType(getNotificationCount),
            debounceTime(1000),
            switchMap(() => {
                return this.http
                    .get<BellNotificationCountModel>('/api/bell-notifications/count')
                    .pipe(map((s) => getNotificationCountSuccess(s)));
            }),
        ),
    );

    markAsRead$ = createEffect(() =>
        this.actions$.pipe(
            ofType(markAsRead),
            switchMap((action) => {
                return this.http
                    .patch<BellNotificationCountModel>('/api/bell-notifications/mark-as-read', {
                        readNotifications: action.ids,
                        signalRConnectionId: this.signalR.connectionId,
                    })
                    .pipe(map((s) => markAsReadSuccess(s)));
            }),
        ),
    );

    markAllAsRead$ = createEffect(() =>
        this.actions$.pipe(
            ofType(markAllAsRead),
            switchMap(() => {
                return this.http
                    .patch<BellNotificationCountModel>('/api/bell-notifications/mark-all-as-read', {
                        signalRConnectionId: this.signalR.connectionId,
                    })
                    .pipe(map((s) => markAsReadSuccess(s)));
            }),
        ),
    );

    changeMatchInvitationState$ = createEffect(() =>
        this.actions$.pipe(
            ofType(changeMatchInvitationState),
            switchMap(({ id, state }) => {
                const model: MatchInvitationUpdateModel = {
                    state: state,
                };
                return this.http.patch(`/api/match-invitations/${id}`, model).pipe(map(() => getNotificationCount()));
            }),
        ),
    );

    addCommentToMatchInvitation$ = createEffect(() =>
        this.actions$.pipe(
            ofType(matchInvitationAddComment),
            switchMap(({ id, comment, imageUrl }) => {
                return this.http
                    .post(`/api/match-invitations/${id}/comments`, { message: comment, imageUrl: imageUrl })
                    .pipe(map(() => matchInvitationAddCommentSuccess()));
            }),
        ),
    );

    constructor(
        private readonly actions$: Actions,
        private readonly http: HttpClient,
        private readonly signalR: SignalRService,
        private readonly store: Store<AppState>,
    ) {}
}
