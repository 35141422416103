import { changeSeason, loadSeasonsSuccess, toggleSeasonSelect } from './season.actions';
import { ISeasonState } from './season.model';
import { _ } from 'core/utils';
import { createReducer, on, Action, createSelector } from '@ngrx/store';
import { AppState } from 'store';

const initialState: ISeasonState = {
    all: [],
    active: null,
    showSelect: true,
};

const reducer = createReducer(
    initialState,
    on(changeSeason, (state, { payload }) => ({ ...state, active: payload })),
    on(loadSeasonsSuccess, (state, { current, seasons }) => ({
        ...state,
        all: [{ id: null, name: _('SEASONS.ALL'), start: null, end: null }, ...seasons],
        active: current || {
            id: null,
            start: null,
            end: null,
            name: _('SEASONS.ALL'),
        },
    })),
    on(toggleSeasonSelect, (state, { allowSelect }) => ({ ...state, showSelect: allowSelect })),
);

export function seasonReducer(state: ISeasonState = initialState, action: Action): ISeasonState {
    return reducer(state, action);
}

const selectSeasons = (state: AppState) => state.season;
export const selectActiveSeason = createSelector(selectSeasons, (state: ISeasonState) => state.active);
