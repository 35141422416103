<div class="l-match">
    <div class="match-date" *ngIf="match.showDate">
        {{ match.createdAt | moment: 'DATE_HUGE' }}
    </div>
    <div *ngIf="match.startedAt; else noStartTime" class="dashboard_time text-muted">
        {{ match.startedAt | moment: 'TIME_SIMPLE' }} - {{ match.endedAt | moment: 'TIME_SIMPLE' }}
    </div>
    <ng-template #noStartTime>
        <div class="match-time text-muted">{{ match.createdAt | moment: 'TIME_SIMPLE' }}</div>
    </ng-template>
    <ng-template #emptyAvatar>
        <div class="avatar"></div>
    </ng-template>

    <app-match-box-header-one-against-one
        [match]="match"
        *ngIf="match.settings.gameMode === gameMode.OneAgainstOne"
    ></app-match-box-header-one-against-one>
    <app-match-box-header-two-against-two
        [match]="match"
        *ngIf="match.settings.gameMode === gameMode.TwoAgainstTwo"
    ></app-match-box-header-two-against-two>

    <div class="l-score" (dblclick)="matchClick(match)">
        <div
            class="score"
            [class.clickable]="ma.goals"
            [class.selected]="setIx === selectedSet"
            (click)="selectSet(setIx)"
            *ngFor="let ma of match.sets; let setIx = index"
        >
            <div class="score_numbers" [class.shame]="ma.scoreTeamA === 0 || ma.scoreTeamB === 0">
                <span class="count score" [class.dark]="ma.scoreTeamA > ma.scoreTeamB">{{ ma.scoreTeamA }}</span>
                <div class="colon-small">:</div>
                <span class="count score" [class.dark]="ma.scoreTeamA < ma.scoreTeamB">{{ ma.scoreTeamB }}</span>
            </div>
        </div>
    </div>
    <div class="comments">
        <div class="header">
            <mat-icon
                (mouseenter)="hoveredEmoji = matchEmojiType.ThumbsUp"
                (mouseleave)="hoveredEmoji = null"
                class="clickable mt-3 mx-2"
                [class.active]="isEmojiActive(matchEmojiType.ThumbsUp)"
                (click)="onEmojiClick($event, matchEmojiType.ThumbsUp)"
                [matBadge]="getEmojiCount(matchEmojiType.ThumbsUp)"
                [matBadgeHidden]="getEmojiCount(matchEmojiType.ThumbsUp) === 0"
                [matTooltip]="'MATCHBOX.THUMBS_UP' | translate"
                [matTooltipPosition]="'above'"
                >thumb_up
            </mat-icon>
            <mat-icon
                (mouseenter)="hoveredEmoji = matchEmojiType.ThumbsDown"
                (mouseleave)="hoveredEmoji = null"
                class="clickable mt-3 mx-2"
                [class.active]="isEmojiActive(matchEmojiType.ThumbsDown)"
                (click)="onEmojiClick($event, matchEmojiType.ThumbsDown)"
                [matBadge]="getEmojiCount(matchEmojiType.ThumbsDown)"
                [matBadgeHidden]="getEmojiCount(matchEmojiType.ThumbsDown) === 0"
                [matTooltip]="'MATCHBOX.THUMBS_DOWN' | translate"
                [matTooltipPosition]="'above'"
                >thumb_down</mat-icon
            >
            <mat-icon
                (click)="onCommentsClick()"
                class="clickable mt-3 mx-2"
                [matBadge]="match.commentsCount"
                [matBadgeHidden]="!match.comments.length"
                [matTooltip]="'MATCHBOX.COMMNETS' | translate"
                [matTooltipPosition]="'above'"
                >comment</mat-icon
            >
            <mat-icon
                (click)="onAchievementsClick()"
                class="clickable mt-3 mx-2"
                [matBadge]="match.achievements.length"
                *ngIf="match.achievements.length"
                [matTooltip]="'MATCHBOX.ACHIEVEMENTS' | translate"
                [matTooltipPosition]="'above'"
                >emoji_events</mat-icon
            >
        </div>
    </div>
    <div class="comments text-center" *ngIf="hoveredEmoji" [@fasterToggleAnimation]>{{ getEmojiPlayers(hoveredEmoji) }}</div>
    <h4 class="my-3" *ngIf="!showComments && match.sets[selectedSet]?.goals?.length">
        {{ 'DASHBOARD.MATCHBOX_SET_SET' | translate: { set: selectedSet + 1 } }}
    </h4>
    <app-match-box-goals
        style="width: 100%; max-width: 600px"
        *ngIf="!showComments && match.sets[selectedSet]?.goals?.length"
        [@toggleAnimation]
        [match]="match"
        [selectedSet]="selectedSet"
    ></app-match-box-goals>

    <app-match-box-achievements
        *ngIf="showAchievements"
        style="width: 100%; max-width: 600px"
        [achievements]="match.achievements"
        [@toggleAnimation]
    >
    </app-match-box-achievements>
    <div class="comments-container" *ngIf="showComments" [@toggleAnimation]>
        <div class="my-2" *ngFor="let comment of match.comments">
            <app-comment [comment]="comment" [level]="0"></app-comment>
        </div>
        <app-upsert-comment [focusOnInit]="match.comments.length === 0" [idMatch]="match.id" class="m-2 p-2"></app-upsert-comment>
    </div>
</div>
